/*
 * @Author: zhuyan 
 * @Date: 2021-11-25 15:39:38 
 * 公司资产 => 软件账号详情
 */
import React, { useState, useEffect } from 'react'
import {
	Descriptions,
	Spin,
	message,
	Steps,
	Button,
	Form,
	Table
} from 'antd'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'

const { Column } = Table
const { Step } = Steps
const dItem = Descriptions.item

let pageSize = 10
let currentPage = 1

const SoftDetails = (props) => {
	const { history, location } = props
	const [detail, setDetail] = useState({})
	const [loading, setLoading] = useState(false)
	const [pageId, setPageId] = useState('')
	const [allocateList, setAllocateList] = useState([])
	const [count, setCount] = useState(0)

	useEffect(() => {
		let plaintext = parseSearch(location.search).sign
		let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

		if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
			if (location.search) {
				setPageId(parseSearch(location.search).id)
				getAssetSoftAccountDetail(parseSearch(location.search).id)
			} else {
				history.push({
					pathname: '/corpAssets/software'
				})
			}
		} else {
			message.error('没有本页访问权限')
			setTimeout(() => {
				history.go(-2)
			}, 1000)
		}
	}, [])

	//获取详情
	const getAssetSoftAccountDetail = (id) => {
		setLoading(true)
		api.getAssetSoftAccountDetail({ id: id }).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				setDetail(data)
				getAssetSoftAccountRecordList(data.id)
			} else {
				setDetail({})
			}
			setLoading(false)
		}).catch(() => setLoading(false))
	}

	//调拨列表
	const getAssetSoftAccountRecordList = (id) => {
		setLoading(true)
		api.getAssetSoftAccountRecordList({
			id,
			limit: pageSize,
			page: currentPage
		}).then(res => {
			setAllocateList(res.list)
			setCount(res.count)
			setLoading(false)
		}).catch(() => setLoading(false))
	}

	//调拨列表分页
	const onChangeTable = (pagination) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		getAssetSoftAccountRecordList(pageId)
	}

	//退回列表页
	const onGoBack = () => {
		history.push({ pathname: '/corpAssets/software', state: { goBack: 1 } })
	}

	return (
		<>
			<Spin spinning={loading}>
				{
					parseSearch(location.search).new && parseSearch(location.search).new == 1 && <Button onClick={onGoBack} type="primary" style={{
						position: 'absolute',
						right: '20px',
						top: '-20px'
					}}>返回上一页</Button>
				}
				<div style={{ padding: '5px 0' }}>
					<Descriptions title="" layout="horizontal" column={4} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
						<dItem label="" span={4}>{`${detail.accountPlatform} (资产编号：${detail.number})`}</dItem>
						<dItem label="绑定手机号">{detail.bindPhone}</dItem>
						<dItem label="手机号实名认证人">{detail.bindPhonePersonName}</dItem>
						<dItem label="身份证">{detail.bindPhonePersonIdno}</dItem>
						<dItem label="手机号是否为公司名义开通">{+detail.isNameWithCompany === 0 ? '' : detail.isNameWithCompanyCn}</dItem>
						<dItem label="绑定微信号">{detail.bindWechat}</dItem>
						<dItem label="微信号实名认证人">{detail.bindWechatPersonName}</dItem>
						<dItem label="账号用途" span={2}>{detail.useNote}</dItem>
						<dItem label="备注">{detail.remark}</dItem>
					</Descriptions>
				</div>
				<div className="line"></div>
				<div className="mobile-type">当前状态：<Button type="primary" >{detail.statusCn}</Button></div>
				{
					detail.status == 1 && <>
						<Descriptions title="" layout="horizontal" column={5} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
							<dItem label="现负责人">{detail.userName}</dItem>
							<dItem label="员工编号">{detail.userNumber}</dItem>
							<dItem label="直属领导">{detail.userLeaderName}</dItem>
							<dItem label="所属部门">{detail.userDepartmentName}</dItem>
							<dItem label="所属事业群">{detail.userGroupName}</dItem>
						</Descriptions>
						<Descriptions layout="horizontal" column={5} style={{ borderBottom: 'none', marginTop: '10px' }}>
							<dItem label="所在位置">{detail.address}</dItem>
							<dItem label="领取时间" span={4}>{detail.getDate}</dItem>
						</Descriptions>
					</>
				}
				<Descriptions title="调拨记录" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
					<dItem>
						<Table
							dataSource={allocateList}
							rowKey="id"
							loading={loading}
							pagination={{
								pageSize,
								total: count,
								current: currentPage,
								showQuickJumper: true,
								showSizeChanger: true,
								pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
							}}
							onChange={onChangeTable}
						>
							<Column title="序号" dataIndex="number" />
							<Column title="现负责人" dataIndex="toUserName" />
							<Column title="现负责人员工编号" dataIndex="toUserNumber" />
							<Column title="原负责人" dataIndex="fromUserName" />
							<Column title="原负责人员工编号" dataIndex="fromUserNumber" />
							<Column title="备注" dataIndex="remark" />
							<Column title="更新时间" dataIndex="updateTime" />
						</Table>
					</dItem>
				</Descriptions>
			</Spin>
		</>
	)
}

export default Form.create()(SoftDetails)