/*
 * @Author: zhuyan 
 * @Date: 2021-11-25 15:39:38 
 * 公司资产 => 系统账号添加
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Input,
  Select,
  message,
  DatePicker,
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import moment from 'moment'

const FormItem = Form.Item
const Option = Select.Option

const Addsystem = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { seteditVisible, getList, setformLoading, addType, editId } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [selectData, setSelectData] = useState({
    project: [], // 项目
    company: [], // 主体
    isRequest: false
  })
  const [projectName, setProjectName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [dataVal, setDataVal] = useState('') // 使用人信息
  const [userId, setUserId] = useState('')
  const [detail, setDetail] = useState(null)

  useEffect(() => {
    onEdit()
  }, [])

  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      api.getsysProjectChilds({ pid: 0 }),
      api.getHeadCompanyList({ limit: global.paramsLimit }),
    ]).then(list => {
      setformLoading(false)
      setSelectData({
        project: list[0],
        company: list[1],
        isRequest: true
      })
      return list
    }).catch(() => setformLoading(false))
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  // 编辑框
  const onEdit = () => {
    if (addType == 0) {
      getSelectData()
    }
    if (addType == 1) {
      api.getAssetSystemAccountDetail({ id: editId }).then(res => {
        // console.log(res)
        const { accountName, projectId, companyId, userNumber, password, openingDate, remark,
          companyName, projectName
        } = res
        getSelectData().then(() => {
          setDetail(res)
          onChangeNoInit(userNumber)
          setProjectName(projectName)
          setCompanyName(companyName)
          setFieldsValue({
            accountName,
            projectId,
            companyId,
            userNumber,
            password,
            openingDate: openingDate ? moment(openingDate) : null,
            remark
          })
        })
      })
    }
  }

  // 保存
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        if (userId === '') {
          return message.error("使用人员工编号填写有误，请重新填写")
        }
        setSaveLoading(true)
        vals.openingDate = vals.openingDate ? moment(vals.openingDate).format('YYYY-MM-DD') : undefined
        let params = { ...vals }
        params.companyName = companyName
        params.projectName = projectName
        params.userId = dataVal.id
        params.userName = dataVal.staffName
        params.userDepartmentId = dataVal.departmentId
        params.userDepartmentName = dataVal.departmentName
        params.userGroupId = dataVal.groupId
        params.userGroupName = dataVal.groupName
        api[`setAssetSystemAccount${editId ? 'Edit' : 'Add'}`](params)
          .then(() => {
            setSaveLoading(false)
            seteditVisible(false)
            message.success('保存成功')
            getList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  const onChangeNo = (e) => {
    setDataVal('')
    setUserId('')
    if (e.target.value) {
      api.getStaffInfobyStaffNo({ staffNo: e.target.value }).then(res => {
        if (res.length !== 0) {
          setDataVal(res)
          setUserId(res.id)
        } else {
          setDataVal('')
          setUserId('')
        }
      })
    }
  }

  const onChangeNoInit = (staffNo) => {
    setDataVal('')
    setUserId('')
    if (staffNo) {
      api.getStaffInfobyStaffNo({ staffNo }).then(res => {
        if (res.length !== 0) {
          setDataVal(res)
          setUserId(res.id)
        } else {
          setDataVal('')
          setUserId('')
        }
      })
    }
  }

  return (
    <div>
      <Form onSubmit={formSubmit} {...formItemLayout}>
        {
          editId ?
            <FormItem label="资产编号">
              <span>{detail && detail.number}</span>
            </FormItem> : null
        }
        <FormItem label="账号">
          {getFieldDecorator('accountName', {
            rules: [{ required: true, message: '请输入账号' }]
          })(
            <Input placeholder="请输入账号" />
          )}
        </FormItem>
        <FormItem label="项目">
          {getFieldDecorator('projectId', {
            rules: [{ required: true, message: '请选择项目' }]
          })(
            <Select placeholder="请选择"
              showSearch={true}
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              onSelect={(key, Option) => setProjectName(Option.props.children)}
            >
              {selectData.project.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="公司主体">
          {getFieldDecorator('companyId', {
            rules: [{ required: true, message: '请选择公司主体' }]
          })(
            <Select placeholder="请选择"
              showSearch={true}
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              onSelect={(key, Option) => setCompanyName(Option.props.children)}
            >
              {selectData.company.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="使用人员工编号">
          {getFieldDecorator('userNumber', {
            rules: [{ required: true, message: '请输入使用人员工编号' }]
          })(
            <Input placeholder="请输入使用人员工编号" onChange={onChangeNo} />
          )}
        </FormItem>
        {
          dataVal !== '' &&
          <>
            <FormItem label="使用人">
              <span>{dataVal.staffName}</span>
            </FormItem>
            <FormItem label="所在部门">
              <span>{dataVal.departmentName}</span>
            </FormItem>
            <FormItem label="所在事业群">
              <span>{dataVal.groupName}</span>
            </FormItem>
          </>
        }
        <FormItem label="密码">
          {getFieldDecorator('password', {
            rules: [{ required: false, message: '请输入密码' }]
          })(
            <Input placeholder="请输入密码" />
          )}
        </FormItem>
        <FormItem label="开通时间">
          {getFieldDecorator('openingDate')(
            <DatePicker placeholder="请选择" allowClear={false} style={{ width: '100%' }} />
          )}
        </FormItem>
        <FormItem label="备注信息">
          {getFieldDecorator('remark')(
            <Input.TextArea placeholder="请输入备注信息" />
          )}
        </FormItem>
        <BtnGroup onCancel={() => seteditVisible(false)} loading={saveLoading} />
      </Form>
    </div>
  );
}

export default Form.create()(Addsystem)
