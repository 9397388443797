/**
 * 模块名称: 公司资产 => 硬件设备管理
 * @author liujingxue@372163.com
 */


import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Mobile from './Mobile'
import Details from './Details'

const MobileIndex = ({ match, history }) => {

	return (
		<Switch>
			<Route path={match.path + '/details'} component={Details} />
			<Route component={Mobile} />
		</Switch>
	)
}

export default MobileIndex