/**
 * 模块名称: 公司账户管理
 * @author liujingxue@372163.com
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import './assets/style.scss'
 import InternalAccount from './InternalAccount'
 import Accountlist from './Account'
 
 const CorpAccount = ({ match, history }) => {
 
   return (
     <Switch>
       <Route path={match.path + '/InternalAccount'} component={InternalAccount} />
       <Route path={match.path + '/list'} component={Accountlist} />
     </Switch>
   )
 }
 
 export default CorpAccount