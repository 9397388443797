/*
 * @Author: zhuyan 
 * 公司账户列表
 */
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Account from './Account'

const AccountIndex = ({ match, history }) => {

    return (
        <Switch>
            <Route component={Account} />
        </Switch>
    )
}

export default AccountIndex