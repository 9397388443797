/**
 * 模块名称: 手机号管理 => 编辑
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Form,
	Input,
	Select,
	message,
	DatePicker
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import moment from 'moment'
import BtnGroup from '@/components/BtnGroup'

const Option = Select.Option

const Add = (props) => {
	const { match, location, history, setvisible, getEmployeeList, editId, setformLoading } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
	const [detail, setDetail] = useState({})
	const [registerUserName, setRegisterUserName] = useState('')//存储注册人姓名
	const [registerUserId, setRegisterUserId] = useState('')
	const [registerUserNumber, setRegisterUserNumber] = useState('')//存储注册人员工编号
	const [companyList, setCompanyList] = useState([])//公司主体
	const [brandTypes, setBrandTypes] = useState([])//存储品牌

	useEffect(() => {
		onCommon()
		setformLoading(true)
		api.getAssetPhoneNumberDetail({ id: editId }).then(res => {
			setDetail(res)
			setRegisterUserName(res.registerUserName)
			setRegisterUserNumber(res.registerUserNumber)
			setRegisterUserId(res.registerUserId)
			setFieldsValue({
				phone: res.phone,
				brand: res.brand === 0 ? undefined : res.brand,
				companyId: res.companyId,
				registerUserNumber: res.registerUserNumber,
				remark: res.remark,
				checkinTime: res.checkinTime !== '' ? moment(res.checkinTime) : undefined,
			})
			setformLoading(false)
		}).catch(() => setformLoading(false))
	}, [])

	//公共接口
	const onCommon = () => {
		api.getAssetPhoneNumberBrandTypes().then(res => setBrandTypes(res))//品牌
		api.getHeadCompanyList({ limit: global.paramsLimit }).then(data => setCompanyList(data))//公司主体
	}

	// 保存
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				if (JSON.stringify(registerUserName) === '[]') {
					return message.error('注册人员工编号填写有误，请重新填写')
				}
				api.setAssetPhoneNumberEdit({
					id: editId,
					phone: vals.phone,
					brand: vals.brand ? vals.brand : '',
					companyId: vals.companyId,
					registerUserId: vals.registerUserNumber ? registerUserId : '',
					registerUserName: vals.registerUserNumber ? registerUserName : '',
					registerUserNumber: vals.registerUserNumber ? vals.registerUserNumber : '',
					checkinTime: vals.checkinTime ? moment(vals.checkinTime).format('YYYY-MM-DD') : '',
					remark: vals.remark
				}).then(res => {
					message.success('编辑成功')
					setvisible(false)
					getEmployeeList()
				}).catch(err => console.log(err))
			} 
		})
	}

	//获取输入的注册人编号
	const onRegisterChange = (e) => {
		setRegisterUserNumber(e.target.value)
		if (e.target.value) {
			api.getStaffInfobyStaffNo({ staffNo: e.target.value, isOnJob: 2 }).then(res => {
				if (JSON.stringify(res) !== '[]') {
					setRegisterUserName(res.staffName)
					setRegisterUserId(res.id)
				} else {
					setRegisterUserName(res)
				}
			})
		} else {
			setRegisterUserName('')
		}
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 20 },
		}
	}

	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout}>
				<Form.Item label="资产编号">
					<div>{detail.number}</div>
				</Form.Item>
				<Form.Item label="手机号">
					{getFieldDecorator('phone', {
						rules: [{ required: true, message: '请输入手机号' }]
					})(<Input placeholder="请输入手机号" />)}
				</Form.Item>
				<Form.Item label="品牌">
					{getFieldDecorator('brand')(
						<Select placeholder="请选择品牌" >
							{brandTypes.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="公司主体">
					{getFieldDecorator('companyId', {
						rules: [{ required: true, message: '请选择公司主体' }]
					})(
						<Select placeholder="请选择公司主体" >
							{companyList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="注册人员工编号">
					{getFieldDecorator('registerUserNumber', {
						rules: [{ required: false, message: '请输入注册人员工编号' }]
					})(<Input placeholder="请输入注册人员工编号" onChange={onRegisterChange} />)}
				</Form.Item>
				{
					registerUserName !== '' && JSON.stringify(registerUserName) !== '[]' && <Form.Item label="注册人">
						<div>{registerUserName}</div>
					</Form.Item> 
				}
				<Form.Item label="备注信息">
					{getFieldDecorator('remark', {
						rules: [{ required: false, message: '请输入备注' }]
					})(<Input.TextArea placeholder="请输入备注" rows={5} cols={250} />)}
				</Form.Item>
				<Form.Item label="入库时间">
					{getFieldDecorator('checkinTime', {
					})(
						<DatePicker placeholder="请选择入库时间" allowClear={false}/>
					)}
				</Form.Item>
				<BtnGroup onCancel={() => setvisible(false)} />
			</Form>
		</>
	)
}

export default Form.create()(Add)