/**
 * 模块名称: 公司资产 => 硬件设备管理 => 编辑
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Form,
	Input,
	Select,
	message,
	DatePicker,
	Upload,
	Button
} from 'antd'
import api from '@/api'
import { upLoadModule } from '@/utils/common'
import moment from 'moment'
import BtnGroup from '@/components/BtnGroup'

const Option = Select.Option

const Add = (props) => {
	const { match, location, history, setvisible, getEmployeeList, editId, setformLoading } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
	const draggerRef = useRef(null)
	const [detail, setDetail] = useState({})
	const [companyList, setCompanyList] = useState([])//公司主体
	const [fileList, setFileList] = useState([])//上传图片

	useEffect(() => {
		onCommon()
		setformLoading(true)
		api.getAssetHardwareDetail({ id: editId }).then(res => {
			setDetail(res)
			setFieldsValue({
				number: res.number,
				technicalNumber: res.technicalNumber,
				snNumber: res.snNumber,
				name: res.name,
				brand: res.brand === 0 ? undefined : res.brand,
				type: res.type,
				companyId: res.companyId,
				codeNumber: res.codeNumber,
				identityNumber: res.identityNumber,
				remark: res.remark,
				buyUrl: res.buyUrl,
				checkinTime: res.checkinTime !== '' ? moment(res.checkinTime) : undefined,
			})
			if (Array.isArray(res.imgUrl) && res.imgUrl.length > 0) {
				let files = res.imgUrl
				files.forEach((item, index) => {
					fileList.push({
						status: 'done',
						uid: index,
						name: item.name,
						url: item.url
					})
				})
				setFileList(files.map((item, index) => ({ ...item, uid: index })))
			}
			setformLoading(false)
		}).catch(() => setformLoading(false))
	}, [])

	//公共接口
	const onCommon = () => {
		api.getHeadCompanyList({ limit: global.paramsLimit }).then(data => setCompanyList(data))//公司主体
	}

	// 保存
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				//上传图片删除
				let uploadList = []
				for (let i = 0, k = fileList.length; i < k; i++) {
					if (fileList[i].status !== 'removed') {
						uploadList.push({
							url: fileList[i].url,
							name: fileList[i].name
						})
					}
				}
				api.setAssetHardwareEdit({
					id: editId,
					number: vals.number,
					technicalNumber: vals.technicalNumber ? vals.technicalNumber : '',
					snNumber: vals.snNumber ? vals.snNumber : '',
					name: vals.name,
					brand: vals.brand ? vals.brand : '',
					type: vals.type,
					companyId: vals.companyId,
					codeNumber: vals.codeNumber ? vals.codeNumber : '',
					identityNumber: vals.identityNumber ? vals.identityNumber : '',
					remark: vals.remark ? vals.remark : '',
					imgUrl: uploadList.length > 0 ? uploadList : '',
					buyUrl: vals.buyUrl ? vals.buyUrl : '',
					checkinTime: vals.checkinTime ? moment(vals.checkinTime).format('YYYY-MM-DD') : '',
				}).then(res => {
					message.success('编辑成功')
					setvisible(false)
					getEmployeeList()
				}).catch(err => console.log(err))
			}
		})
	}

	// 附件图片
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.jpg, .png, .jpeg'
	})

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 20 },
		}
	}

	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout}>
				<Form.Item label="资产编号">
					{getFieldDecorator('number', {
						rules: [{ required: true, message: '请输入资产编号' }]
					})(<Input placeholder="请输入资产编号" />)}
				</Form.Item>
				<Form.Item label="技术编号">
					{getFieldDecorator('technicalNumber')(<Input placeholder="请输入技术编号" />)}
				</Form.Item>
				<Form.Item label="SN编号">
					{getFieldDecorator('snNumber')(<Input placeholder="请输入SN编号" />)}
				</Form.Item>
				<Form.Item label="资产名称">
					{getFieldDecorator('name', {
						rules: [{ required: true, message: '请输入资产名称' }]
					})(<Input placeholder="请输入资产名称" />)}
				</Form.Item>
				<Form.Item label="资产品牌">
					{getFieldDecorator('brand')(<Input placeholder="请输入资产品牌" />)}
				</Form.Item>
				<Form.Item label="资产类型">
					{getFieldDecorator('type', {
						rules: [{ required: true, message: '请输入资产类型' }]
					})(<Input placeholder="请输入资产类型" />)}
				</Form.Item>
				<Form.Item label="公司主体">
					{getFieldDecorator('companyId', {
						rules: [{ required: true, message: '请选择公司主体' }]
					})(
						<Select placeholder="请选择公司主体" >
							{companyList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="喷码编号">
					{getFieldDecorator('codeNumber')(<Input placeholder="请输入喷码编号" />)}
				</Form.Item>
				<Form.Item label="识别码">
					{getFieldDecorator('identityNumber')(<Input placeholder="请输入识别码" />)}
				</Form.Item>
				<Form.Item label="备注信息">
					{getFieldDecorator('remark')(<Input.TextArea placeholder="请输入备注信息" rows={5} cols={250} />)}
				</Form.Item>
				<Form.Item label="上传图片" >
					<div className="dragger-box" ref={draggerRef}>
						<Upload
							{...uploadFiles}
						>
							<Button type="primary">上传图片</Button>
							<p className="ant-upload-hint">
								支持扩展名：.png、.jpg、.jpeg 单个文件大小不超过5M，不超过5个附件
							</p>
						</Upload>
					</div>
				</Form.Item>
				<Form.Item label="购买地址">
					{getFieldDecorator('buyUrl')(<Input placeholder="请输入购买地址" />)}
				</Form.Item>
				<Form.Item label="入库时间">
					{getFieldDecorator('checkinTime', {
					})(
						<DatePicker placeholder="请选择入库时间" allowClear={false} />
					)}
				</Form.Item>
				<BtnGroup onCancel={() => setvisible(false)} />
			</Form>
		</>
	)
}

export default Form.create()(Add)