/**
 * 模块名称: 公司资产 => 硬件设备管理 => 调拨
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Form,
	Input,
	Select,
	message
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import BtnGroup from '@/components/BtnGroup'

const Option = Select.Option

const Add = (props) => {
	const { match, location, history, setvisible, getEmployeeList, editId, setformLoading } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
	const [detail, setDetail] = useState({})
	const [busStatusList, setBusStatusList] = useState([])//获取状态列表
	const [status, setStatus] = useState('')//获取当前状态
	const [userNumberDetail, setUserNumberDetail] = useState({})//获取使用人的信息
	const [userNumber, setUserNumber] = useState('')//存储使用人员工编号

	useEffect(() => {
		onCommon()
		setformLoading(true)
		api.getAssetHardwareDetail({ id: editId }).then(res => {
			setDetail(res)
			setStatus(res.status)
			setFieldsValue({
				status: res.status,
				// remark: res.remark,
			})
			if (res.status == 2) {
				setUserNumber(res.userNumber)
				setFieldsValue({
					userNumber: res.userNumber
				})
				if (res.userNumber) {
					api.getStaffInfobyStaffNo({ staffNo: res.userNumber }).then(res => {
						if (JSON.stringify(res) !== '[]') {
							setUserNumberDetail(res)
						} else {
							setUserNumberDetail({})
						}
					})
				} else {
					setUserNumberDetail({})
				}
			}
			setformLoading(false)
		}).catch(() => setformLoading(false))
	}, [])

	//公共接口
	const onCommon = () => {
		api.getAssetHardwareStatus().then(res => setBusStatusList(res)).catch(err => console.log(err))//获取状态
	}

	// 保存
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				let obj = {}
				if (vals.status == 2 && JSON.stringify(userNumberDetail) == '{}') {
					return message.error('使用人员工编号填写有误，请重新填写')
				}
				obj.id = editId
				obj.status = vals.status ? vals.status : ''
				obj.receiveUser = vals.receiveUser ? vals.receiveUser : ''
				obj.remark = vals.remark ? vals.remark : ''
				obj.location = vals.location ?  vals.location : ''
				obj.userId = vals.userNumber ? userNumberDetail.id : ''
				obj.userName = vals.userNumber ? userNumberDetail.staffName : ''
				obj.userNumber = vals.userNumber ? vals.userNumber : ''
				obj.userLeaderId = vals.userNumber ? userNumberDetail.leaderId : ''
				obj.userLeaderName = vals.userNumber ? userNumberDetail.leaderName : ''
				obj.userGroupId = vals.userNumber ? userNumberDetail.groupId : ''
				obj.userGroupName = vals.userNumber ? userNumberDetail.groupName : ''
				obj.userDepartmentId = vals.userNumber ? userNumberDetail.departmentId : ''
				obj.userDepartmentName = vals.userNumber ? userNumberDetail.departmentName : ''
				api.setAssetHardwareAllocate(obj).then(res => {
					message.success('调拨成功')
					setvisible(false)
					getEmployeeList()
				}).catch(err => console.log(err))
			}
		})
	}

	//选择状态
	const onSelectStatus = (val) => {
		setStatus(val)
	}

	//获取输入的使用人员工编号
	const onUserChange = (e) => {
		setUserNumber(e.target.value)
		if (e.target.value) {
			api.getStaffInfobyStaffNo({ staffNo: e.target.value }).then(res => {
				if (JSON.stringify(res) !== '[]') {
					setUserNumberDetail(res)
				} else {
					setUserNumberDetail({})
				}
			})
		} else {
			setUserNumberDetail({})
		}

	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 20 },
		}
	}

	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout}>
				<Form.Item label="资产编号">
					<div>{detail.number}</div>
				</Form.Item>
				<Form.Item label="技术编号">
					<div>{detail.technicalNumber}</div>
				</Form.Item>
				<Form.Item label="资产名称">
					<div>{detail.name}</div>
				</Form.Item>
				<Form.Item label="资产类型">
					<div>{detail.type}</div>
				</Form.Item>
				<Form.Item label="状态">
					{getFieldDecorator('status', {
						rules: [{ required: false, message: '请选择状态' }]
					})(
						<Select placeholder="请选择状态" onSelect={onSelectStatus}>
							{busStatusList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</Form.Item>
				{
					status === 2 && <>
						<Form.Item label="使用人员工编号">
							{getFieldDecorator('userNumber', {
								rules: [{ required: true, message: '请输入注册人员工编号' }]
							})(<Input placeholder="请输入注册人员工编号" onChange={onUserChange} />)}
						</Form.Item>
						{
							JSON.stringify(userNumberDetail) !== '{}' && <>
								<Form.Item label="使用人姓名">
									<div>{userNumberDetail.staffName}</div>
								</Form.Item>
								<Form.Item label="直属上级">
									<div>{userNumberDetail.leaderName}</div>
								</Form.Item>
								<Form.Item label="所属部门">
									<div>{userNumberDetail.departmentName}</div>
								</Form.Item>
								<Form.Item label="所在事业群">
									<div>{userNumberDetail.groupName}</div>
								</Form.Item>
								<Form.Item label="所在位置">
									{getFieldDecorator('location', {
										initialValue: detail.location,
										rules: [{ required: false, message: '请输入所在位置' }]
									})(<Input placeholder="请输入所在位置" />)}
								</Form.Item>
								<Form.Item label="领用人">
									{getFieldDecorator('receiveUser', {
										initialValue: detail.receiveUser,
										rules: [{ required: true, message: '请输入领取人' }]
									})(<Input placeholder="请输入领取人" />)}
								</Form.Item>
							</>
						}
					</>
				}
				<Form.Item label="备注">
					{getFieldDecorator('remark', {
						rules: [{ required: false, message: '请输入备注' }]
					})(<Input.TextArea placeholder="请输入备注" rows={5} cols={250} />)}
				</Form.Item>
				<BtnGroup onCancel={() => setvisible(false)} />
			</Form>
		</>
	)
}

export default Form.create()(Add)