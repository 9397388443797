/**
 * 模块名称: 公司资产 => 手机号管理详情页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
	Descriptions,
	Spin,
	message,
	Steps,
	Button,
	Form,
	Table
} from 'antd'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'
import '../assets/style.scss'

const { Column } = Table
const { Step } = Steps
const dItem = Descriptions.item

let areaTxt = '', result = ''

let pageSize = 10
let currentPage = 1

const Details = (props) => {
	const { history, location } = props
	const [detail, setDetail] = useState({})
	const [loading, setLoading] = useState(false)
	const [pageId, setPageId] = useState('')
	const [allocateList, setAllocateList] = useState([])
	const [count, setCount] = useState(0)

	useEffect(() => {
		let plaintext = parseSearch(location.search).sign
		let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

		if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
			areaTxt = ''
			result = ''
			if (location.search) {
				setPageId(parseSearch(location.search).id)
				getAssetPhoneNumberDetail(parseSearch(location.search).id)
			} else {
				history.push({
					pathname: '/corpAssets/mobile'
				})
			}
		} else {
			message.error('没有本页访问权限')
			setTimeout(() => {
				history.go(-2)
			}, 1000)
		}
	}, [])

	//获取详情
	const getAssetPhoneNumberDetail = (id) => {
		setLoading(true)
		api.getAssetPhoneNumberDetail({ id: id }).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				setDetail(data)
				getAssetAllocateList(data.id)
			} else {
				setDetail({})
			}
			setLoading(false)
		}).catch(() => setLoading(false))
	}

	//调拨列表
	const getAssetAllocateList = (id) => {
		setLoading(true)
		api.getAssetAllocateList({
			assetsId: id,
			assetsType: 2,
			limit: pageSize,
			page: currentPage
		}).then(res => {
			// console.log(res)
			setAllocateList(res.list)
			setCount(res.count)
			setLoading(false)
		}).catch(() => setLoading(false))
	}

	//调拨列表分页
	const onChangeTable = (pagination) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		getAssetAllocateList(pageId)
	}

	//退回列表页
	const onGoBack = () => {
		history.push({ pathname: '/corpAssets/mobile', state: { goBack: 1 } })
	}

	return (
		<>
			<Spin spinning={loading}>
				{
					parseSearch(location.search).new && parseSearch(location.search).new == 1 && <Button onClick={onGoBack} type="primary" style={{
						position: 'absolute',
						right: 0,
						top: '-20px'
					}}>返回上一页</Button>
				}
				<div style={{ padding: '5px 0' }}>
					<Descriptions title="" layout="horizontal" column={4} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
						<dItem label="资产编号">{detail.number}</dItem>
						<dItem label="手机号">{detail.phone}</dItem>
						<dItem label="注册人">{detail.registerUserName}</dItem>
						<dItem label="备注信息">{detail.remark}</dItem>
					</Descriptions>
				</div>
				<div className="line"></div>
				<div className="mobile-type">当前状态：<Button type="primary" >{detail.statusName}</Button></div>
				{
					detail.status == 1 && <>
						<Descriptions title="" layout="horizontal" column={4} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
							<dItem label="现使用人">{detail.userName}</dItem>
							<dItem label="员工编号">{detail.userNumber}</dItem>
							<dItem label="使用人直属领导">{detail.userLeaderName}</dItem>
							<dItem label="所属部门">{detail.userGroupName}</dItem>
						</Descriptions>
						<Descriptions layout="horizontal" column={4} style={{ borderBottom: 'none', marginTop: '10px' }}>
							<dItem label="所在位置">{detail.location}</dItem>
							<dItem label="领取时间">{detail.receiveTime}</dItem>
						</Descriptions>
					</>
				}
				<Descriptions title="调拨记录" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
					<dItem>
						<Table
							dataSource={allocateList}
							rowKey="id"
							loading={loading}
							pagination={{
								pageSize,
								total: count,
								current: currentPage,
								showQuickJumper: true,
								showSizeChanger: true,
								pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
							}}
							onChange={onChangeTable}
						>
							<Column title="序号" dataIndex="number" />
							<Column title="现使用人" dataIndex="toUserName" />
							<Column title="现使用人员工编号" dataIndex="toUserNumber" />
							<Column title="原使用人" dataIndex="fromUserName" />
							<Column title="原使用人员工编号" dataIndex="fromUserNumber" />
							<Column title="备注" dataIndex="remark" />
							<Column title="更新时间" dataIndex="modifyTime" />
						</Table>
					</dItem>
				</Descriptions>
			</Spin>
		</>
	)
}

export default Form.create()(Details)