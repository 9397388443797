/**
 * 模块名称: 公司资产
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Mobile from './Mobile'
import Hardware from './Hardware'
import System from './System'
import Software from './Software'
import './assets/style.scss'

const CorpAssetsIndex = ({ match, history }) => {

	return (
		<Switch>
			<Route path={match.path + '/hardware'} component={Hardware} />
			<Route path={match.path + '/mobile'} component={Mobile} />
			<Route path={match.path + '/system'} component={System} />
			<Route path={match.path + '/software'} component={Software} />
			<Route component={Hardware} />
		</Switch>
	)
}

export default CorpAssetsIndex