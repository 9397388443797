/**
 * 模块名称: 手机号管理 => 添加
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Form,
	Input,
	Select,
	message,
	DatePicker
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import moment from 'moment'
import BtnGroup from '@/components/BtnGroup'

const Option = Select.Option

const Add = (props) => {
	const { match, location, history, setvisible, getEmployeeList } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

	const [companyList, setCompanyList] = useState([])
	const [saveLoading, setSaveLoading] = useState(false)
	const [brandTypes, setBrandTypes] = useState([])//存储品牌
	const [busStatusList, setBusStatusList] = useState([])//获取状态
	const [registerUserNumber, setRegisterUserNumber] = useState('')//存储注册人员工编号
	const [registerUserDetail, setRegisterUserDetail] = useState({})//获取注册人的信息
	const [userNumber, setUserNumber] = useState('')//存储使用人员工编号
	const [userNumberDetail, setUserNumberDetail] = useState({})//获取使用人的信息
	const [status, setStatus] = useState('')

	useEffect(() => {
		onCommon()
	}, [])

	//公共接口
	const onCommon = () => {
		api.getAssetPhoneNumberBrandTypes().then(res => setBrandTypes(res))//品牌
		api.getHeadCompanyList({ limit: global.paramsLimit }).then(data => setCompanyList(data))//公司主体
		api.getAssetPhoneNumberStatus().then(res => setBusStatusList(res)).catch(err => console.log(err))//获取状态
	}

	// 保存
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				// console.log('vals=>', vals)
				let obj = {}
				if (vals.status == 1 && JSON.stringify(userNumberDetail) == '{}') {
					return message.error('使用人员工编号填写有误，请重新填写')
				}
				if (status === 1) {
					obj.userId = userNumberDetail.id
					obj.userName = userNumberDetail.staffName
					obj.userNumber = vals.userNumber
					obj.userLeaderId = userNumberDetail.leaderId
					obj.userLeaderName = userNumberDetail.leaderName
					obj.userGroupId = userNumberDetail.groupId
					obj.userGroupName = userNumberDetail.groupName
					obj.userDepartmentId = userNumberDetail.departmentId
					obj.userDepartmentName = userNumberDetail.departmentName
				} else {
					obj.userId = ''
					obj.userName = ''
					obj.userNumber = ''
					obj.userLeaderId = ''
					obj.userLeaderName = ''
					obj.userGroupId = ''
					obj.userGroupName = ''
					obj.userDepartmentId = ''
					obj.userDepartmentName = ''
				}
				obj.phone = vals.phone
				obj.brand = vals.brand ? vals.brand : ''
				obj.companyId = vals.companyId
				obj.registerUserId = vals.registerUserNumber ? registerUserDetail.id : ''
				obj.registerUserName = vals.registerUserNumber ? registerUserDetail.staffName : ''
				obj.registerUserNumber = vals.registerUserNumber ? vals.registerUserNumber : ''
				obj.status = vals.status ? vals.status : ''
				obj.checkinTime = vals.checkinTime ? moment(vals.checkinTime).format('YYYY-MM-DD') : ''
				obj.location = vals.location ? vals.location : ''
				obj.remark = vals.remark ? vals.remark : ''
				if (JSON.stringify(registerUserDetail) === '[]') {
					return message.error('注册人员工编号填写有误，请重新填写')
				}
				// console.log('obj=>', obj)
				api.setAssetPhoneNumberAdd(obj).then(res => {
					message.success('添加成功')
					setvisible(false)
					getEmployeeList()
				}).catch(err => console.log(err))
			}
		})
	}

	//获取输入的注册人编号
	const onRegisterChange = (e) => {
		setRegisterUserNumber(e.target.value)
		if (e.target.value) {
			api.getStaffInfobyStaffNo({ staffNo: e.target.value, isOnJob: 2 }).then(res => {
				setRegisterUserDetail(res)
				// if (JSON.stringify(res) !== '[]') {
				// 	setRegisterUserDetail(res)
				// } else {
				// 	setRegisterUserDetail({})
				// }
			})
		} else {
			setRegisterUserDetail({})
		}
	}

	//获取输入的使用人员工编号
	const onUserChange = (e) => {
		setUserNumber(e.target.value)
		if (e.target.value) {
			api.getStaffInfobyStaffNo({ staffNo: e.target.value }).then(res => {
				if (JSON.stringify(res) !== '[]') {
					setUserNumberDetail(res)
				} else {
					setUserNumberDetail({})
				}
			})
		} else {
			setUserNumberDetail({})
		}
	}

	//选择状态
	const onSelectStatus = (val) => {
		setStatus(val)
		setUserNumberDetail({})
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 20 },
		}
	}

	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout}>
				<Form.Item label="手机号">
					{getFieldDecorator('phone', {
						rules: [{ required: true, message: '请输入手机号' }]
					})(<Input placeholder="请输入手机号" />)}
				</Form.Item>
				<Form.Item label="品牌">
					{getFieldDecorator('brand')(
						<Select placeholder="请选择品牌" >
							{brandTypes.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="公司主体">
					{getFieldDecorator('companyId', {
						rules: [{ required: true, message: '请选择公司主体' }]
					})(
						<Select placeholder="请选择公司主体" >
							{companyList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="注册人员工编号">
					{getFieldDecorator('registerUserNumber', {
						rules: [{ required: false, message: '请输入注册人员工编号' }]
					})(<Input placeholder="请输入注册人员工编号" onChange={onRegisterChange}/>)}
				</Form.Item>
				{
					JSON.stringify(registerUserDetail) !== '{}' && JSON.stringify(registerUserDetail) !== '[]' && <Form.Item label="注册人">
						<div>{registerUserDetail.staffName}</div>
					</Form.Item>
				}
				<Form.Item label="状态">
					{getFieldDecorator('status', {
						rules: [{ required: true, message: '请选择状态' }]
					})(
						<Select placeholder="请选择状态" onSelect={onSelectStatus}>
							{busStatusList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</Form.Item>
				{
					status === 1 && <>
						<Form.Item label="使用人员工编号">
							{getFieldDecorator('userNumber', {
								rules: [{ required: true, message: '请输入使用人员工编号' }]
							})(<Input placeholder="请输入使用人员工编号" onChange={onUserChange}/>)}
						</Form.Item>
						{
							JSON.stringify(userNumberDetail) !== '{}' && <>
								<Form.Item label="使用人姓名">
									<div>{userNumberDetail.staffName}</div>
								</Form.Item>
								<Form.Item label="直属上级">
									<div>{userNumberDetail.leaderName}</div>
								</Form.Item>
								<Form.Item label="所属部门">
									<div>{userNumberDetail.departmentName}</div>
								</Form.Item>
								<Form.Item label="所在事业群">
									<div>{userNumberDetail.groupName}</div>
								</Form.Item>
								<Form.Item label="所在位置">
									{getFieldDecorator('location', {
										rules: [{ required: false, message: '请输入所在位置' }]
									})(<Input placeholder="请输入所在位置" />)}
								</Form.Item>
							</>
						}
					</>
				}
				<Form.Item label="入库时间">
					{getFieldDecorator('checkinTime', {
					})(
						<DatePicker placeholder="请选择入库时间" />
					)}
				</Form.Item>
				<Form.Item label="备注信息">
					{getFieldDecorator('remark', {
						rules: [{ required: false, message: '请输入备注' }]
					})(<Input.TextArea placeholder="请输入备注" rows={5} cols={250} />)}
				</Form.Item>
				<BtnGroup onCancel={() => setvisible(false)} />
			</Form>
		</>
	)
}

export default Form.create()(Add)