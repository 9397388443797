/**
 * 模块名称: 公司资产 => 硬件设备管理 => 添加
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Form,
	Input,
	Select,
	message,
	DatePicker,
	Upload,
	Button
} from 'antd'
import api from '@/api'
import { upLoadModule } from '@/utils/common'
import moment from 'moment'
import BtnGroup from '@/components/BtnGroup'

const Option = Select.Option

const Add = (props) => {
	const { match, location, history, setvisible, getEmployeeList } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
	const draggerRef = useRef(null)
	const [companyList, setCompanyList] = useState([])
	const [busStatusList, setBusStatusList] = useState([])//获取状态
	const [userNumber, setUserNumber] = useState('')//存储使用人员工编号
	const [userNumberDetail, setUserNumberDetail] = useState({})//获取使用人的信息
	const [fileList, setFileList] = useState([])//上传图片
	const [status, setStatus] = useState('')

	useEffect(() => {
		onCommon()
	}, [])

	//公共接口
	const onCommon = () => {
		api.getHeadCompanyList({ limit: global.paramsLimit }).then(data => setCompanyList(data))//公司主体
		api.getAssetHardwareStatus().then(res => setBusStatusList(res)).catch(err => console.log(err))//获取状态
	}

	// 保存
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				// console.log('vals=>', vals)
				//上传图片删除
				let uploadList = []
				for (let i = 0, k = fileList.length; i < k; i++) {
					if (fileList[i].status !== 'removed') {
						uploadList.push({
							url: fileList[i].url,
							name: fileList[i].name
						})
					}
				}
				let obj = {}
				if (vals.status == 2 && JSON.stringify(userNumberDetail) == '{}') {
					return message.error('使用人员工编号填写有误，请重新填写')
				}
				if (status === 2) {
					obj.userId = userNumberDetail.id
					obj.userName = userNumberDetail.staffName
					obj.userNumber = vals.userNumber
					obj.userLeaderId = userNumberDetail.leaderId
					obj.userLeaderName = userNumberDetail.leaderName
					obj.userGroupId = userNumberDetail.groupId
					obj.userGroupName = userNumberDetail.groupName
					obj.userDepartmentId = userNumberDetail.departmentId
					obj.userDepartmentName = userNumberDetail.departmentName
				} else {
					obj.userId = ''
					obj.userName = ''
					obj.userNumber = ''
					obj.userLeaderId = ''
					obj.userLeaderName = ''
					obj.userGroupId = ''
					obj.userGroupName = ''
					obj.userDepartmentId = ''
					obj.userDepartmentName = ''
				}
				obj.number = vals.number
				obj.technicalNumber = vals.technicalNumber ? vals.technicalNumber : ''
				obj.snNumber = vals.snNumber ? vals.snNumber : ''
				obj.name = vals.name
				obj.brand = vals.brand ? vals.brand : ''
				obj.type = vals.type
				obj.companyId = vals.companyId
				obj.codeNumber = vals.codeNumber ? vals.codeNumber : ''
				obj.identityNumber = vals.identityNumber ? vals.identityNumber : ''
				obj.status = vals.status
				obj.remark = vals.remark ? vals.remark : ''
				obj.imgUrl = uploadList.length > 0 ? uploadList : ''
				obj.buyUrl = vals.buyUrl ? vals.buyUrl : ''
				obj.checkinTime = vals.checkinTime ? moment(vals.checkinTime).format('YYYY-MM-DD') : ''
				obj.receiveUser = vals.receiveUser ? vals.receiveUser : ''
				obj.location = vals.location ? vals.location : ''
				// console.log(obj)
				api.setAssetHardwareAdd(obj).then(res => {
					message.success('添加成功')
					setvisible(false)
					getEmployeeList()
				}).catch(err => console.log(err))
			}
		})
	}

	//获取输入的使用人员工编号
	const onUserChange = (e) => {
		setUserNumber(e.target.value)
		if (e.target.value) {
			api.getStaffInfobyStaffNo({ staffNo: e.target.value }).then(res => {
				// console.log(res)
				if (JSON.stringify(res) !== '[]') {
					setUserNumberDetail(res)
				} else {
					setUserNumberDetail({})
				}
			})
		} else {
			setUserNumberDetail({})
		}
	}

	//选择状态
	const onSelectStatus = (val) => {
		setStatus(val)
	}

	// 附件图片
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.jpg, .png, .jpeg'
	})

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 20 },
		}
	}

	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout}>
				<Form.Item label="资产编号">
					{getFieldDecorator('number', {
						rules: [{ required: true, message: '请输入资产编号' }]
					})(<Input placeholder="请输入资产编号" />)}
				</Form.Item>
				<Form.Item label="技术编号">
					{getFieldDecorator('technicalNumber')(<Input placeholder="请输入技术编号" />)}
				</Form.Item>
				<Form.Item label="SN编号">
					{getFieldDecorator('snNumber')(<Input placeholder="请输入SN编号" />)}
				</Form.Item>
				<Form.Item label="资产名称">
					{getFieldDecorator('name', {
						rules: [{ required: true, message: '请输入资产名称' }]
					})(<Input placeholder="请输入资产名称" />)}
				</Form.Item>
				<Form.Item label="资产品牌">
					{getFieldDecorator('brand')(<Input placeholder="请输入资产品牌" />)}
				</Form.Item>
				<Form.Item label="资产类型">
					{getFieldDecorator('type', {
						rules: [{ required: true, message: '请输入资产类型' }]
					})(<Input placeholder="请输入资产类型" />)}
				</Form.Item>
				<Form.Item label="公司主体">
					{getFieldDecorator('companyId', {
						rules: [{ required: true, message: '请选择公司主体' }]
					})(
						<Select placeholder="请选择公司主体" >
							{companyList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="喷码编号">
					{getFieldDecorator('codeNumber')(<Input placeholder="请输入喷码编号" />)}
				</Form.Item>
				<Form.Item label="识别码">
					{getFieldDecorator('identityNumber')(<Input placeholder="请输入识别码" />)}
				</Form.Item>
				<Form.Item label="状态">
					{getFieldDecorator('status', {
						rules: [{ required: true, message: '请选择状态' }]
					})(
						<Select placeholder="请选择状态" onSelect={onSelectStatus}>
							{busStatusList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</Form.Item>
				{
					status === 2 && <>
						<Form.Item label="使用人员工编号">
							{getFieldDecorator('userNumber', {
								rules: [{ required: true, message: '请输入使用人员工编号' }]
							})(<Input placeholder="请输入使用人员工编号" onChange={onUserChange} />)}
						</Form.Item>
						{
							JSON.stringify(userNumberDetail) !== '{}' && <>
								<Form.Item label="使用人姓名">
									<div>{userNumberDetail.staffName}</div>
								</Form.Item>
								<Form.Item label="直属上级">
									<div>{userNumberDetail.leaderName}</div>
								</Form.Item>
								<Form.Item label="所属部门">
									<div>{userNumberDetail.departmentName}</div>
								</Form.Item>
								<Form.Item label="所属事业群">
									<div>{userNumberDetail.groupName}</div>
								</Form.Item>
								<Form.Item label="所在位置">
									{getFieldDecorator('location', {
										rules: [{ required: false, message: '请输入所在位置' }]
									})(<Input placeholder="请输入所在位置" />)}
								</Form.Item>
								<Form.Item label="领用人">
									{getFieldDecorator('receiveUser', {
										rules: [{ required: true, message: '请输入领取人' }]
									})(<Input placeholder="请输入领取人" />)}
								</Form.Item>
							</>
						}
					</>
				}
				<Form.Item label="备注信息">
					{getFieldDecorator('remark', {
						rules: [{ required: false, message: '请输入备注' }]
					})(<Input.TextArea placeholder="请输入备注" rows={5} cols={250} />)}
				</Form.Item>
				<Form.Item label="上传图片" >
					<div className="dragger-box" ref={draggerRef}>
						<Upload
							{...uploadFiles}
						>
							<Button type="primary">上传图片</Button>
							<p className="ant-upload-hint">
								支持扩展名：.png、.jpg、.jpeg 单个文件大小不超过5M，不超过5个附件
							</p>
						</Upload>
					</div>
				</Form.Item>
				<Form.Item label="购买地址">
					{getFieldDecorator('buyUrl')(<Input placeholder="请输入购买地址" />)}
				</Form.Item>
				<Form.Item label="入库时间">
					{getFieldDecorator('checkinTime', {
						rules: [{ required: true, message: '请输入入库时间' }]
					})(
						<DatePicker placeholder="请选择入库时间" />
					)}
				</Form.Item>
				<BtnGroup onCancel={() => setvisible(false)} />
			</Form>
		</>
	)
}

export default Form.create()(Add)