/*
 * @Author: zhuyan 
 * @Date: 2021-11-24 17:07:42 
 * 公司资产 => 系统账号管理
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import System from './System'
 
 const SystemIndex = ({ match, history }) => {
 
   return (
     <Switch>
       <Route component={System} />
     </Switch>
   )
 }
 
 export default SystemIndex