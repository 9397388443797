/**
 * 模块名称: 公司内部往来款列表
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
	Table,
	Form,
	Input,
	Button,
	Select,
	message,
	DatePicker,
	Row,
	Col,
	Popconfirm,
	Tooltip,
	Switch
} from 'antd'
import CorpFilter from '@/components/CorpQuanQian'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let companyId = []
let searchParamsTmp = {
	limit: pageSize,
	page: currentPage,
	companyId: companyId,
	shouldMe: 0
}

const busStatusList = [{
	id: 1,
	name: '审批中'
}, {
	id: 2,
	name: '已驳回'
}, {
	id: 3,
	name: '待支付'
}, {
	id: 4,
	name: '已付款'
}, {
	id: 5,
	name: '终止付款'
}]

const InternalAccount = props => {
	const { history, match, location } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
	const [authList, setAuthList] = useState([])
	const [count, setCount] = useState(0)
	const [list, setList] = useState([])
	const [loading, setLoading] = useState(false)
	const [visible, setVisible] = useState(false)
	const [costClassList, setCostClassList] = useState([])//获取费用类别
	const [payWayList, setPayWayList] = useState([])//获取付款方式
	const [isShow, setIsShow] = useState(false)//待审批
	const search = parseSearch(props.location.search)

	useEffect(() => {
		// console.log(history.location.state)
		if (!history.location.state) {
			currentPage = search.page ? +search.page : 1
			pageSize = search.limit ? +search.limit : 10
			searchParamsTmp = {
				limit: pageSize,
				page: currentPage,
				shouldMe: 0
			}
		} else {
			currentPage = search.page ? +search.page : currentPage
			pageSize = search.limit ? +search.limit : pageSize
			initGoback()
		}
		companyId = []
		common()
		getInnerFundTransferList()
		api.getPageAuth().then(list => setAuthList(list))
	}, [])

	//公共接口
	const common = () => {
		api.getInnerFundTransferAccountList({ limit: global.paramsLimit }).then(res => {
			console.log(res)
			setCostClassList(res.list)
		}).catch(err => console.log(err))//转出传入账户
		api.getAllCompanyList({ limit: global.paramsLimit }).then(data => setPayWayList(data.list)).catch(err => console.log(err))//获取公司列表
	}

	const initGoback = () => {
		// console.log(searchParamsTmp)
		let timer = setTimeout(() => {
			initFieldsVal(searchParamsTmp)
			setFieldsValue({
				apply: searchParamsTmp.applyStart ? [moment(searchParamsTmp.applyStart), moment(searchParamsTmp.applyEnd)] : undefined
			})
			clearTimeout(timer)
		}, 1000)
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}

	//点击选择所属公司
	const onChangeCorp = (data) => {
		currentPage = 1
		history.replace(match.path)
		if (data.length) {
			let arr = []
			data.forEach((item, index) => {
				arr.push(item.id)
			})
			companyId = [].concat(arr)
			searchParamsTmp = {
				limit: pageSize,
				page: currentPage,
				companyId: companyId,
				shouldMe: 0
			}
		} else {
			companyId = []
			searchParamsTmp = {
				limit: pageSize,
				page: currentPage,
				shouldMe: 0
			}
		}
		getInnerFundTransferList()
	}

	//点击重置
	const onReset = () => {
		currentPage = 1
		history.replace(match.path)
		searchParamsTmp = {
			limit: pageSize,
			page: currentPage,
			companyId: companyId,
			shouldMe: 0
		}
		resetFields()
		setIsShow(false)
		getInnerFundTransferList()
	}

	//分页点击
	const onChangeTable = (pagination, filters, sorter) => {
		searchParamsTmp.page = currentPage = pagination.current
		searchParamsTmp.limit = pageSize = pagination.pageSize
		history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
		getInnerFundTransferList()
	}

	//获取列表
	const getInnerFundTransferList = () => {
		setLoading(true)
		if (companyId.length) searchParamsTmp.companyId = companyId
		api.getInnerFundTransferList(searchParamsTmp).then(res => {
			setCount(res.count)
			setList(res.list)
			setLoading(false)
		}).catch(() => {
			setLoading(false)
		})
	}

	//数据转换
	const transData = (vals) => {
		if (vals !== undefined) {
			for (let i in vals) {
				if (vals[i] !== undefined && i == 'apply') {
					searchParamsTmp.applyStart = moment(vals[i][0]).format('YYYY-MM-DD')
					searchParamsTmp.applyEnd = moment(vals[i][1]).format('YYYY-MM-DD')
				}
				// else if (i == 'userName') {
				// 	if (typeof parseInt(vals[i]) === 'number' && !isNaN(vals[i])) {
				// 		//纯数字
				// 		searchParamsTmp.userNumber = vals[i]
				// 	} else {
				// 		//非纯数字
				// 		searchParamsTmp.userName = vals[i]
				// 	}
				// } 
				else {
					searchParamsTmp[i] = vals[i]
				}
			}
			// console.log('searchParamsTmp=>', searchParamsTmp)
		}
	}

	//点击筛选
	const searchSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			//console.log(vals)
			transData(vals)
			if (!err) {
				searchParamsTmp.page = currentPage = 1
				history.replace(match.path)
				getInnerFundTransferList()
			}
		})

	}

	// 填充表单值
	const initFieldsVal = data => {
		setFieldsValue({
			id: data.id ? data.id : undefined,
			type: data.type ? data.type : undefined,
			fromAccountId: data.fromAccountId ? data.fromAccountId : undefined,
			fromCompanyId: data.fromCompanyId ? data.fromCompanyId : undefined,
			toAccountId: data.toAccountId ? data.toAccountId : undefined,
			toCompanyId: data.toCompanyId ? data.toCompanyId : undefined,
			status: data.status ? data.status : undefined,
			applyStafName: data.applyStafName ? data.applyStafName : undefined,
		})
	}

	//操作=> 删除
	const onDelete = (record) => {
		api.getInnerFundTransferDelete({ id: record.id }).then(res => {
			message.success('删除成功')
			getInnerFundTransferList()
		})
	}

	//待审批
	const onSetShouldMe = (checked) => {
		currentPage = 1
		setIsShow(checked)
		if (checked) {
			searchParamsTmp.shouldMe = 1
		} else {
			searchParamsTmp.shouldMe = 0
		}
		setTimeout(() => {
			getInnerFundTransferList()
		}, 600)
	}

	return (
		<>
			{/* <CorpFilter onChange={onChangeCorp} part={true} /> */}
			<div className="comm-stay-lists">
				<Form onSubmit={searchSubmit} {...formItemLayout}>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="业务编号">
								{getFieldDecorator('id')(<Input
									allowClear={true}
								// placeholder="请输入业务编号"
								/>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="转款类型">
								{getFieldDecorator('type')(<Select
									placeholder="全部"
									allowClear={true}
									showSearch={true}
									optionFilterProp="children"
									dropdownMatchSelectWidth={false}
								>
									<Option value={1}>募投账户转款</Option>
									<Option value={2}>研发账户转款</Option>
									<Option value={3}>银行转账</Option>
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="转出账户">
								{getFieldDecorator('fromAccountId')(<Select
									placeholder="全部"
									allowClear={true}
									showSearch={true}
									optionFilterProp="children"
									dropdownMatchSelectWidth={false}
								>
									{
										costClassList.map(v => {
											return <Option key={v.id}>{v.accountName}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="转出账户主体">
								{getFieldDecorator('fromCompanyId')(<Select
									placeholder="全部"
									allowClear={true}
									dropdownMatchSelectWidth={false}
								>
									{
										payWayList.map((item, index) => {
											return <Option key={item.id}>{item.name}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>			
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="转入账户">
								{getFieldDecorator('toAccountId')(<Select
									placeholder="全部"
									allowClear={true}
									dropdownMatchSelectWidth={false}
								>
									{
										costClassList.map((item, index) => {
											return <Option key={item.id}>{item.accountName}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="转入账户主体">
								{getFieldDecorator('toCompanyId')(<Select
									placeholder="全部"
									allowClear={true}
									dropdownMatchSelectWidth={false}
								>
									{
										payWayList.map((item, index) => {
											return <Option key={item.id}>{item.name}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="状态">
								{getFieldDecorator('status')(<Select
									placeholder="全部"
									allowClear={true}
								>
									{
										busStatusList.map(item => {
											return <Option key={item.id}>{item.name}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="申请人">
								{getFieldDecorator('applyStafName')(<Input
									allowClear={true}
								// placeholder="请输入申请人"
								/>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="申请时间">
								{getFieldDecorator('apply', {
								})(<RangePicker allowClear={false} />)}
							</FormItem>
						</Col>
					</Row>
					<div className="comm-stay-btns" >
						<Switch
							checkedChildren="待审批"
							unCheckedChildren="待审批"
							checked={isShow}
							onClick={onSetShouldMe}
							style={{ marginRight: '24px' }}
							className="shouldme-switch-btn"
						/>
						<BtnGroup cancelName="重置" confirmName="查询" onCancel={onReset} />
					</div>
				</Form>
			</div>
			<div className="line"></div>
			<div className="add-wrap">
				<Auth auths={authList} code="transmit-money-apply">
					<Link to={`${match.path}/add`} style={{ cursor: 'pointer' }}>
						<Button type="primary">转款申请</Button>
					</Link>
				</Auth>
			</div>
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				loading={loading}
				className="askforleave-table"
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				onChange={onChangeTable}
				scroll={{ x: 1600 }}
			> 
				<Column title="业务编号" dataIndex="id" fixed="left" />
				<Column title="转款类型" dataIndex="typeName" /> 
				<Column title="转出账户" dataIndex="fromAccountName" /> 
				<Column title="转出账户主体" dataIndex="fromCompanyName" />
				<Column title="转入账户" dataIndex="toAccountName" />
				<Column title="转入账户主体" dataIndex="toCompanyName" />
				<Column title="转款金额" dataIndex="money" />
				<Column title="状态" dataIndex="statusName" />
				<Column title="申请人" dataIndex="applyStafName" />
				<Column title="申请时间" dataIndex="createTime" />
				<Column title="操作" key="oprate" fixed="right" width={100} render={(text, record) => {
					return <>
						<div>
							{record.hasDetail && <Link to={`${match.path}/details?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>详情</Link>}
						</div>
						{record.hasEdit && <div>
							<Link to={`${match.path}/add?id=${record.id}`}>编辑</Link>
						</div>}
						{record.hasDel && <div>
							<Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record)}>
								<a>删除</a>
							</Popconfirm>
						</div>}
					</>
				}} />
			</Table>
		</>
	)
}

export default Form.create()(InternalAccount)