/**
 * 模块名称: 公司资产 => 手机号管理列表
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
	Table,
	Form,
	Input,
	DatePicker,
	Select,
	message,
	Row,
	Col,
	Button,
	Drawer,
	Popconfirm,
	Spin,
	Icon,
	Modal
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpQuanQian'
import Import from '@/components/Import'
import BtnGroup from '@/components/BtnGroup'
import Add from './Add'
import Edit from './Edit'
import Allot from './Allot'
import CryptoJS from 'crypto-js'
import '../assets/style.scss'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
let apiOperateTimer = true

const { RangePicker } = DatePicker

//所属公司筛选
let filter = {
	companyId: ''
}

//筛选初始值
let exporfilter = {
	number: '',
	phone: '',
	userName: '',
	userNumber: '',
	registerUserName: '',
	registerUserNumber: '',
	userGroupId: '',
	userDepartmentId: '',
	status: '',
	checkinStartTime: '',
	checkinEndTime: '',
	receiveStartTime: '',
	receiveEndTime: '',
	cancelStartTime: '',
	cancelEndTime: ''
}

let params = {}

const Mobile = (props) => {
	const ImportRef = useRef()
	const { match, location, history } = props
	const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [count, setCount] = useState(0)
	const [groupList, setGroupList] = useState([])//获取事业群
	const [departmentList, setDepartmentList] = useState([])//获取部门
	const [busStatusList, setBusStatusList] = useState([])//获取状态
	const [showImport, setShowImport] = useState(false)//导入
	const [formLoading, setformLoading] = useState(false)//抽屉Loading
	const [visible, setvisible] = useState(false)//抽屉的显示隐藏
	const [type, setType] = useState('')
	const [titleDrawer, setTitleDrawer] = useState('')
	const [editId, setEditId] = useState('')
	const [authList, setAuthList] = useState([])
	const [expand, setExpand] = useState(false)//展开收起
	const [alterationUnfoldPack, setAlterationUnfoldPack] = useState(true)
  const [alterationTextUnfoldPack, setAlterationTextUnfoldPack] = useState(true)

	const search = parseSearch(location.search)

	useEffect(() => {
		filter = {
			companyId: ''
		}
		if (!history.location.state) {
			currentPage = search.page ? +search.page : 1
			pageSize = search.limit ? +search.limit : 10
			exporfilter = {
				number: '',
				phone: '',
				userName: '',
				userNumber: '',
				registerUserName: '',
				registerUserNumber: '',
				userGroupId: '',
				userDepartmentId: '',
				status: '',
				checkinStartTime: '',
				checkinEndTime: '',
				receiveStartTime: '',
				receiveEndTime: '',
				cancelStartTime: '',
				cancelEndTime: ''
			}
			getAssetPhoneNumberList()
		} else {
			currentPage = search.page ? +search.page : currentPage
			pageSize = search.limit ? +search.limit : pageSize
			setTimeout(() => {
				setFieldsValue({
					number: exporfilter.number !== '' ? exporfilter.number : undefined,
					phone: exporfilter.phone !== '' ? exporfilter.phone : undefined,
					userName: exporfilter.userName !== '' ? exporfilter.userName : undefined,
					userNumber: exporfilter.userNumber !== '' ? exporfilter.userNumber : undefined,
					registerUserName: exporfilter.registerUserName !== '' ? exporfilter.registerUserName : undefined,
					registerUserNumber: exporfilter.registerUserNumber !== '' ? exporfilter.registerUserNumber : undefined,
					userGroupId: exporfilter.userGroupId !== '' ? exporfilter.userGroupId : undefined,
					userDepartmentId: exporfilter.userDepartmentId !== '' ? exporfilter.userDepartmentId : undefined,
					status: exporfilter.status !== '' ? exporfilter.status : undefined,
					checkinTime: exporfilter.checkinStartTime !== '' ? [moment(exporfilter.checkinStartTime), moment(exporfilter.checkinEndTime)] : undefined,
					receiveTime: exporfilter.receiveStartTime !== '' ? [moment(exporfilter.receiveStartTime), moment(exporfilter.receiveEndTime)] : undefined,
					cancelTime: exporfilter.cancelStartTime !== '' ? [moment(exporfilter.cancelStartTime), moment(exporfilter.cancelEndTime)] : undefined
				})
			}, 1000)
			getEmployeeList()
		}
		apiOperateTimer = true
		onCommon()
		api.getPageAuth().then(list => setAuthList(list))
	}, [])

	//获取列表
	const getAssetPhoneNumberList = () => {
		if (apiOperateTimer) {
			setLoading(true)
			let params = {
				limit: pageSize,
				page: currentPage,
				companyId: filter.companyId
			}
			api.getAssetPhoneNumberList(params).then(data => {
				setList(data.list)
				setCount(data.count)
				setLoading(false)
			}).catch(() => setLoading(false))
			apiOperateTimer = false
			let timer = setTimeout(() => {
				apiOperateTimer = true
				clearTimeout(timer)
			}, 1000)
		} else {
			return message.info('请不要频繁操作')
		}
	}

	//获取公共接口
	const onCommon = () => {
		api.getSelectGroupList({ limit: global.paramsLimit }).then(res => setGroupList(res.list)).catch(err => console.log(err))//获取事业群
		Department()//部门
		api.getAssetPhoneNumberStatus().then(res => setBusStatusList(res)).catch(err => console.log(err))//获取状态
	}

	//获取部门
	const Department = (val) => {
		api.getSelectDepartmentList({ 
			limit: global.paramsLimit, 
			groupId: val 
		}).then(res => {
			setDepartmentList(res.list)
		}).catch(err => console.log(err))
	}

	//筛选时调接口
	const getEmployeeList = () => {
		setLoading(true)
		api.getAssetPhoneNumberList({
			limit: pageSize,
			page: currentPage,
			companyId: filter.companyId,
			number: exporfilter.number,
			phone: exporfilter.phone,
			userName: exporfilter.userName,
			userNumber: exporfilter.userNumber,
			registerUserName: exporfilter.registerUserName,
			registerUserNumber: exporfilter.registerUserNumber,
			userGroupId: exporfilter.userGroupId,
			userDepartmentId: exporfilter.userDepartmentId,
			status: exporfilter.status,
			checkinStartTime: exporfilter.checkinStartTime,
			checkinEndTime: exporfilter.checkinEndTime,
			receiveStartTime: exporfilter.receiveStartTime,
			receiveEndTime: exporfilter.receiveEndTime,
			cancelStartTime: exporfilter.cancelStartTime,
			cancelEndTime: exporfilter.cancelEndTime
		}).then(data => {
			setList(data.list)
			setCount(data.count)
			setLoading(false)
		}).catch(() => setLoading(false))
	}

	//点击筛选提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			//const values = Object.values(vals)
			//console.log(values)
			if (!err) {
				// console.log(vals)
				currentPage = 1
				history.replace(match.path)
				if(!vals.userGroupId) {
					Department()
				}
				exporfilter.number = vals.number ? vals.number : ''
				exporfilter.phone = vals.phone ? vals.phone : ''
				exporfilter.userName = vals.userName ? vals.userName : ''
				exporfilter.userNumber = vals.userNumber ? vals.userNumber : ''
				exporfilter.registerUserName = vals.registerUserName ? vals.registerUserName : ''
				exporfilter.registerUserNumber = vals.registerUserNumber ? vals.registerUserNumber : ''
				exporfilter.userGroupId = vals.userGroupId ? vals.userGroupId : ''
				exporfilter.userDepartmentId = vals.userDepartmentId ? vals.userDepartmentId : ''
				exporfilter.status = vals.status ? vals.status : ''
				exporfilter.checkinStartTime = vals.checkinTime ? vals.checkinTime[0].format('YYYY-MM-DD') : ''
				exporfilter.checkinEndTime = vals.checkinTime ? vals.checkinTime[1].format('YYYY-MM-DD') : ''
				exporfilter.receiveStartTime = vals.receiveTime ? vals.receiveTime[0].format('YYYY-MM-DD') : ''
				exporfilter.receiveEndTime = vals.receiveTime ? vals.receiveTime[1].format('YYYY-MM-DD') : ''
				exporfilter.cancelStartTime = vals.cancelTime ? vals.cancelTime[0].format('YYYY-MM-DD') : ''
				exporfilter.cancelEndTime = vals.cancelTime ? vals.cancelTime[1].format('YYYY-MM-DD') : ''
				getEmployeeList()
			}
		})
	}

	const onReset = (e) => {
		e.preventDefault()
		exporfilter = {
			number: '',
			phone: '',
			userName: '',
			userNumber: '',
			registerUserName: '',
			registerUserNumber: '',
			userGroupId: '',
			userDepartmentId: '',
			status: '',
			checkinStartTime: '',
			checkinEndTime: '',
			receiveStartTime: '',
			receiveEndTime: '',
			cancelStartTime: '',
			cancelEndTime: ''
		}
		resetFields()
		currentPage = 1
		history.replace(match.path)
		Department()
		getEmployeeList()
	}

	//筛选
	const onCorpFilter = (data) => {
		filter.companyId = data.map(item => item.id).join(',')
		history.replace(match.path)
		currentPage = 1
		getAssetPhoneNumberList()
	}

	const onChangeTable = (pagination) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
		getEmployeeList()
	}

	//删除
	const onDelete = (data) => {
		api.setAssetPhoneNumberDelete({id: data.id}).then(res => {
			message.success('删除成功')
			getEmployeeList()
		})
	}

	//选择事业群
	const onSelectGroup = (data) => {
		Department(data)
	}

	//添加打开抽屉
	const onAddDrawer = (val, title, id) => {
		if (id) {
			setEditId(id)
		}
		setType(val)
		setTitleDrawer(title)
		setvisible(true)
	}

	//导入
	const onShowImport = () => {
		ImportRef.current.onRemoved()
    setShowImport(true)
	}

	// 导入确定
  const onConfirmImport = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== 'removed') {
        api.setAssetPhoneNumberImport({
          uploadUrl: fileList[0].url
        }).then(res => {
          getEmployeeList()
          message.success('导入成功')
          ImportRef.current.onLoading()
          setShowImport(false)
        }).catch(res => {
          // console.log('导入', res)
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

		
	//展开收起
	const toggle = () => {
    setExpand(!expand)
    setAlterationUnfoldPack(!alterationUnfoldPack)
    setAlterationTextUnfoldPack(!alterationTextUnfoldPack)
  }

	const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

	return (
		<>
			<CorpFilter onChange={onCorpFilter} />
			<div className="contract-warp">
				<h4 className="title">其它选项：</h4>
				<span className="contract-btn-a" onClick={toggle}>
					{alterationTextUnfoldPack ? '展开' : '收起'}<Icon type={expand ? 'up' : 'down'} />
				</span>
				<Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="资产编号">
								{getFieldDecorator('number')(<Input allowClear={true} placeholder="请输入资产编号" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="手机号">
								{getFieldDecorator('phone')(<Input allowClear={true} placeholder="请输入手机号" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="使用人">
								{getFieldDecorator('userName')(<Input allowClear={true} placeholder="请输入使用人" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="使用人员工编号">
								{getFieldDecorator('userNumber')(<Input allowClear={true} placeholder="请输入使用人员工编号" />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}  className={alterationUnfoldPack ? "contract-unfold" : ""}>
						<Col span={6}>
							<FormItem label="注册人">
								{getFieldDecorator('registerUserName')(<Input allowClear={true} placeholder="请输入注册人" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="注册人员工编号">
								{getFieldDecorator('registerUserNumber')(<Input allowClear={true} placeholder="请输入注册人员工编号" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="使用人所在事业群">
								{getFieldDecorator('userGroupId')(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
										onSelect={onSelectGroup}
									>
										{
											groupList.map(v => {
												return <Option key={v.id}>{v.name}</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="使用人所在部门">
								{getFieldDecorator('userDepartmentId')(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
									>
										{
											departmentList.map(v => {
												return <Option key={v.id}>{v.name}（{v.companyName}）</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}  className={alterationUnfoldPack ? "contract-unfold" : ""}>
						<Col span={6}>
							<FormItem label="状态">
								{getFieldDecorator('status')(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
									>
										{
											busStatusList.map(v => {
												return <Option key={v.id}>{v.name}</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="入库时间">
								{getFieldDecorator('checkinTime')(<RangePicker allowClear={false} />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="领用时间">
								{getFieldDecorator('receiveTime')(<RangePicker allowClear={false} />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="注销时间">
								{getFieldDecorator('cancelTime')(<RangePicker allowClear={false} />)}
							</FormItem>
						</Col>
					</Row>
					<div className="contract-filter">
            <BtnGroup cancelName="重置" confirmName="筛选" onCancel={onReset} />
          </div>
				</Form>
			</div>
			<div className="line"></div>
			<div className="add-wrap">
				<Auth auths={authList} code="add">
					<Button type="primary" onClick={() => onAddDrawer(1, '资产添加')}>添加</Button>
					<Drawer
						title={titleDrawer}
						width={800}
						onClose={() => setvisible(false)}
						visible={visible}
						bodyStyle={{ paddingBottom: 80 }}
						destroyOnClose={true}
					>
						<Spin spinning={formLoading}>
							{type === 1 && <Add setvisible={setvisible} getEmployeeList={getEmployeeList} />}
							{type === 2 && <Edit
								setvisible={setvisible}
								getEmployeeList={getEmployeeList}
								editId={editId}
								setformLoading={setformLoading}
							/>}
							{type === 3 && <Allot
								setvisible={setvisible}
								getEmployeeList={getEmployeeList}
								editId={editId}
								setformLoading={setformLoading}
							/>}
						</Spin>
					</Drawer>
				</Auth>
				<Auth auths={authList} code="import">
					<Button type="primary" style={{ marginLeft: '20px' }} onClick={onShowImport}>导入</Button>
				</Auth>
				<Import
					onSearch={onConfirmImport}
					visibleFile={showImport}
					onCloseImport={() => setShowImport(false)}
					ImportTemplate="/api/File/DownTemplate?url=/templates/phoneNumber.xlsx&name=手机号导入模板"
					ImportRef={ImportRef}
				/>
			</div>
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				loading={loading}
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				scroll={{ x: 2200 }}
				onChange={onChangeTable}
			>
				<Column title="资产编号" dataIndex="number" fixed="left" width={100} />
				<Column title="手机号" dataIndex="phone" />
				<Column title="品牌" dataIndex="brandName" />
				<Column title="公司主体" dataIndex="companyName" />
				<Column title="现使用人" dataIndex="userName" />
				<Column title="使用人员工编号" dataIndex="userNumber" />
				<Column title="注册人" dataIndex="registerUserName" />
				<Column title="注册人员工编号" key='registerUserNumber' render={(text, record) => (
					<div>{record.registerUserNumber == 0 ? '' : record.registerUserNumber}</div>
				)}/>
				<Column title="使用人所在部门" dataIndex="userDepartmentName" />
				<Column title="使用人所在事业群" dataIndex="userGroupName" />
				<Column title="状态" dataIndex="statusName" />
				<Column title="入库时间" dataIndex="checkinTime" />
				<Column title="领取时间" dataIndex="receiveTime" />
				<Column title="注销时间" dataIndex="cancelTime" />
				<Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
					<div>
						{record.isDetail && <Link to={`${match.path}/details?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>详情</Link>}
						{record.isEdit && <div onClick={() => onAddDrawer(2, '手机号信息编辑', record.id)}><a>编辑</a></div>}
						{record.isChange && <div onClick={() => onAddDrawer(3, '资产调拨', record.id)}><a >调拨</a></div>}
						{record.isDelete && <div>
							<Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record)}>
								<a>删除</a>
							</Popconfirm>
						</div>}
					</div>
				)} />
			</Table>
		</>
	)
}

export default Form.create()(Mobile)