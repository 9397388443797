import React from 'react'
import { Switch, Route } from 'react-router-dom'
import InternalAccount from './InternalAccount'
import Details from './Details'
import Add from './Add'

const InternalAccountIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/details'} component={Details} />
      <Route path={match.path + '/add'} component={Add} />
      <Route component={InternalAccount} />
    </Switch>
  )
}

export default InternalAccountIndex