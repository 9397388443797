/**
 * 模块名称: 公司内部往来款列表=>申请编辑页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Select,
	Input,
	DatePicker,
	InputNumber,
	Form,
	message,
	Row,
	Col,
	Upload,
	Icon,
	Tooltip,
	TreeSelect
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import moment from 'moment'
import '../assets/style.scss'

const { Option } = Select
const { TreeNode } = TreeSelect
const { Dragger } = Upload
const FormItem = Form.Item
const { RangePicker } = DatePicker

var temp

const Add = (props) => {
	const { location, history } = props
	const { getFieldDecorator, setFieldsValue, validateFields } = props.form

	const draggerRef = useRef(null)
	const [fileList, setFileList] = useState([])
	const [accountList, setAccountList] = useState([])//获取费用类别
	const [moneyDx, setMoneyDx] = useState('')
	const [rolloff, setRolloff] = useState({})
	const [into, setInto] = useState({})

	const search = parseSearch(location.search)
	
	useEffect(() => {
		//获取公共接口
		api.getInnerFundTransferAccountList({ limit: global.paramsLimit }).then(res => {
			setAccountList(res.list)
		}).catch(err => console.log(err))
		if (search.id) {
			api.getInnerFundTransferDetail({ id: search.id }, true).then(data => {
				const res = data.basic
				const fileLists = res.attachment
				api.getInnerFundTransferAccountList({ limit: global.paramsLimit }).then(data => {
					let obj1 = data.list.filter(v => v.id == res.fromAccountId)[0]
					obj1 && setRolloff(obj1)
					let obj2 = data.list.filter(v => v.id == res.toAccountId)[0]
					obj2 && setInto(obj2)
					setFieldsValue({
						fromAccountId: obj1 ? res.fromAccountId : undefined,
						toAccountId: obj2 ? res.toAccountId : undefined
					})
				}).catch(err => console.log(err))

				setMoneyDx(DX(res.money))
				setFieldsValue({
					type: res.type,
					money: res.money,
					remark: res.remark
				})
				if (fileLists.length) {
					fileLists.forEach((item, index) => {
						fileList.push({
							status: 'done',
							uid: index,
							name: item.name,
							url: item.url
						})
					})
					setFileList(fileLists.map((item, index) => ({ ...item, uid: index })))
				}
			})
		}
	}, [])

	//添加
	const handleSubmit = e => {
		e.preventDefault()
		validateFields((err, vals) => {
			let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
			if (!err) {
				api.setInnerFundTransferApply({
					id: search.id ? search.id : '',
					type: vals.type,
					fromAccountId: vals.fromAccountId,
					toAccountId: vals.toAccountId,
					money: vals.money,
					remark: vals.remark,
					attachment: uploadList
				}).then(res => {
					message.success('保存成功')
					history.push('/corpAccount/InternalAccount')
				})
			} else {
				message.error('请填写必填内容')
			}
		})
	}

	//取消返回列表页
	const onCancel = () => history.push('/corpAccount/InternalAccount')

	// 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.png, .jpg, .PDF, .zip, .xls, .xlsx',
		limitNum: 20
	})

	//费用类别下拉获取具体名称
	const onSelectRolloff = (values) => {
		let obj = accountList.filter(v => v.id == values)[0]
		setRolloff(obj)
	}

	//
	const onSelectInto = (values) => {
		let obj = accountList.filter(v => v.id == values)[0]
		setInto(obj)
	}

	//付款金额
	const onChangeMoney = (e) => {
		if (e.target.value) {
			setMoneyDx(DX(e.target.value))
		} else {
			setMoneyDx('')
		}

	}

	//转大写
	const DX = (tranvalue) => {
		try {
			var i = 1;
			var dw2 = new Array("", "万", "亿");//大单位
			var dw1 = new Array("拾", "佰", "仟");//小单位
			var dw = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖");//整数部分用
			//以下是小写转换成大写显示在合计大写的文本框中     
			//分离整数与小数
			var source = splits(tranvalue);
			var num = source[0];
			var dig = source[1];

			//转换整数部分
			var k1 = 0;//计小单位
			var k2 = 0;//计大单位
			var sum = 0;
			var str = "";
			var len = source[0].length;//整数的长度
			for (i = 1; i <= len; i++) {
				var n = source[0].charAt(len - i);//取得某个位数上的数字
				var bn = 0;
				if (len - i - 1 >= 0) {
					bn = source[0].charAt(len - i - 1);//取得某个位数前一位上的数字
				}
				sum = sum + Number(n);
				if (sum != 0) {
					str = dw[Number(n)].concat(str);//取得该数字对应的大写数字，并插入到str字符串的前面
					if (n == '0') sum = 0;
				}
				if (len - i - 1 >= 0) {//在数字范围内
					if (k1 != 3) {//加小单位
						if (bn != 0) {
							str = dw1[k1].concat(str);
						}
						k1++;
					} else {//不加小单位，加大单位
						k1 = 0;
						temp = str.charAt(0);
						if (temp == "万" || temp == "亿")//若大单位前没有数字则舍去大单位
							str = str.substr(1, str.length - 1);
						str = dw2[k2].concat(str);
						sum = 0;
					}
				}
				if (k1 == 3)//小单位到千则大单位进一
				{ k2++; }
			}

			//转换小数部分
			var strdig = "";
			if (dig != "") {
				var n = dig.charAt(0);
				if (n != 0) {
					strdig += dw[Number(n)] + "角";//加数字
				}
				var n = dig.charAt(1);
				if (n != 0) {
					strdig += dw[Number(n)] + "分";//加数字
				}
			}
			if (strdig !== '') {
				if (str !== '') {
					str += "元" + strdig;
				} else {
					str += strdig;
				}

			} else {
				str += "元" + strdig + '整'
			}

		} catch (e) {
			return "0元";
		}
		return str;
	}

	//拆分整数与小数
	function splits(tranvalue) {
		var value = new Array('', '');
		temp = tranvalue.split(".");
		for (var i = 0; i < temp.length; i++) {
			value[i] = temp[i];
		}
		return value;
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	}

	return (
		<>
			<div className="cost-management-apply">
				<h1 className="cost-management-apply-title">{search.id ? '转款驳回重提申请' : '转款申请'}</h1>
				<div className="line"></div>
				<Form onSubmit={handleSubmit} {...formItemLayout}>
					<h3 className="cost-management-apply-tit">基本信息</h3>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="转款类型">
								{getFieldDecorator('type', {
									rules: [{ required: true, message: '请选择' }]
								})(<Select
									placeholder="请选择"
									showSearch={true}
									optionFilterProp="children"
									dropdownMatchSelectWidth={false}
								>
									<Option value={1}>募投账户转款</Option>
									<Option value={2}>研发账户转款</Option>
									<Option value={3}>银行转账</Option>
								</Select>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="转出账户">
								{getFieldDecorator('fromAccountId', {
									rules: [{ required: true, message: '请选择' }]
								})(<Select 
									placeholder="请选择" 
									dropdownMatchSelectWidth={false} 
									onSelect={onSelectRolloff}
									optionFilterProp="children"
									showSearch={true}
								>
									{
										accountList.map((v, i) => {
											return <Option value={v.id} key={i}>{v.accountName}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="转出账户主体"><div>{rolloff.corpName}</div></FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="账号"><div>{rolloff.accountNo}</div></FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="开户行"><div>{rolloff.bank}</div></FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="转入账户">
								{getFieldDecorator('toAccountId', {
									rules: [{ required: true, message: '请选择' }]
								})(<Select 
									placeholder="请选择" 
									dropdownMatchSelectWidth={false} 
									onSelect={onSelectInto}
									optionFilterProp="children"
									showSearch={true}
								>
									{
										accountList.map((v, i) => {
											return <Option value={v.id} key={i}>{v.accountName}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="转入账户主体"><div>{into.corpName}</div></FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="账号"><div>{into.accountNo}</div></FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="开户行"><div>{into.bank}</div></FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="转款金额">
								{getFieldDecorator('money', {
									rules: [{ required: true, message: '请输入' }, {
										pattern: /(^-?[0-9]*(\.[0-9]{1,2})?%?$)|(^0%?$)|(^-?[0-9]\.[0-9]([0-9])?%?$)/, message: '请输入正确的格式'
										// /^-?[0-9]*(\.[0-9]*)?$/
									}]
								})(<Input placeholder="请输入" onChange={onChangeMoney} />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label={<span style={{ color: 'red' }}>大写金额</span>}>
								{moneyDx && <div style={{ color: 'red' }}>{moneyDx}</div>}
							</FormItem>
						</Col>
					</Row>
					<h3 className="cost-management-apply-tit">其他信息</h3>

					<div style={{ marginTop: 20 }}>
						<FormItem label="附件" labelCol={{ span: 2 }}>
							<div className="dragger-box" ref={draggerRef}>
								<Dragger
									{...uploadFiles}
								>
									<p className="ant-upload-drag-icon">
										<Icon type="inbox" />
									</p>
									<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
									<p className="ant-upload-hint">
										附件支持格式：xls、xlsx、PNG、JPG、PDF、zip 支持添加多个附件，单个附件大小不超过5M，文件数量不超过20个。
									</p>
								</Dragger>
							</div>
						</FormItem>
					</div>
					<div style={{ marginTop: 20 }}>
						<FormItem label="备注" labelCol={{ span: 2 }}>
							{getFieldDecorator('remark')(
								<Input.TextArea maxLength={100} />
							)}
						</FormItem>
					</div>
					<BtnGroup confirmName="提交" onCancel={onCancel} />
				</Form>
			</div>
		</>
	)
}

export default Form.create()(Add)