/**
 * 模块名称: 公司账户管理 => 公司账户列表
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
    Table,
    Form,
    Alert,
    Button,
    Input,
    message,
    Spin,
    Modal,
    Select,
    Popconfirm
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'

const { Column } = Table
const Option = Select.Option
const FormItem = Form.Item
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

let boardData = {
    keyword: '',
}

const Account = (props) => {
    const { match, history } = props
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [fieldModal, setFieldModal] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [formLoading, setformLoading] = useState(true)
    const [authList, setAuthList] = useState([])
    const [companyType, setCompanyType] = useState([])
    const [corpName, setCorpName] = useState('')
    const [dataVal, setDataVal] = useState('')
    const search = parseSearch(props.location.search)

    useEffect(() => {
        currentPage = search.page ? +search.page : 1
        pageSize = search.limit ? +search.limit : 10
        boardData = {
            keyword: ''
        }
        getCorporateAccountlist()
        api.getPageAuth().then(list => setAuthList(list))
    }, [])

    const getCorporateAccountlist = () => {
        setLoading(true)
        api.getCorporateAccountlist({
            name: boardData.keyword,
            limit: pageSize,
            page: currentPage
        }).then(data => {
            setList(data.list)
            setCount(data.count)
            setLoading(false)
        }).catch(() => setLoading(false))
    }


    const onChangeTable = (pagination, filters, sorter) => {
        currentPage = pagination.current
        pageSize = pagination.pageSize
        history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
        getCorporateAccountlist()
    }

    const deparkeyword = useRef(null)

    const onSearch = () => {
        boardData.keyword = deparkeyword.current.value
        history.replace(match.path)
        currentPage = 1
        getCorporateAccountlist()
    }

    const onReset = () => {
        boardData.keyword = ''
        deparkeyword.current.value = ''
        history.replace(match.path)
        currentPage = 1
        getCorporateAccountlist()
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        }
    }

    const addFields = () => {
        editId = ''
        setDataVal('')
        setFieldModal(true)
        getSelectData()
    }

    const getSelectData = () => {
        setformLoading(true)
        return api.getAllCompanyList().then(res => {
            setCompanyType(res.list)
            setformLoading(false)
            return res.list
        }).catch(() => setformLoading(false))
    }

    // 编辑
    const onEdit = (id) => {
        setFieldModal(true)
        setformLoading(true)
        setDataVal('')
        editId = id
        api.getCorporateAccountDetail({ id }).then(data => {
            const { accountName, corpId, corpName, accountNo, bank, remark } = data
            getSelectData().then(() => {
                setFieldsValue({
                    // accountName,
                    corpId,
                    accountNo,
                    bank,
                    remark
                })
            })
            setDataVal(data)
            setCorpName(corpName)
        }).catch(() => {
            setFieldModal(false)
        })
    }

    const formSubmit = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {
            if (!err) {
                if (!editId && vals.accountName.trim() === '') {
                    message.error('账号名称不能为空')
                    return
                }
                if (vals.accountNo.trim() === '') {
                    message.error('账号不能为空')
                    return
                }
                if (vals.bank.trim() === '') {
                    message.error('开户行不能为空')
                    return
                }
                if (editId) {
                    vals.id = editId
                    vals.accountName = dataVal.accountName
                }
                vals.corpName = corpName
                setSaveLoading(true)
                api.getCorporateAccountHandle(vals).then(() => {
                    setFieldModal(false)
                    setSaveLoading(false)
                    message.success('保存成功')
                    getCorporateAccountlist()
                }).catch(() => setSaveLoading(false))
            }
        })
    }

    const onDelete = (id) => {
        api.getCorporateAccountDel({ id }).then(res => {
            getCorporateAccountlist()
            message.info("删除成功")
        })
    }

    const onKeyup = (e) => {
        if (e.keyCode === 13) {
            onSearch()
        }
    }

    return (
        <>
            <div className="bulle-wrap">
                <div className="filter-item">
                    <label htmlFor="">搜索：</label>
                    <input className="ant-input ant-inputTwo" placeholder="账户名称/公司主体/开户行" ref={deparkeyword} type="keyword" onKeyUp={onKeyup} />
                </div>
                <div className="bulle-wrap-btn">
                    <BtnGroup cancelName="重置" confirmName="查询" onConfirm={onSearch} onCancel={onReset} />
                </div>
            </div>
            <div className="line"></div>
            <div className="bulle-wrap-add">
                {/* <Auth auths={authList} code="add-account"> */}
                    <Button type="primary" onClick={addFields}>新增账户</Button>
                {/* </Auth> */}
            </div>
            <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
            <Table
                size="small"
                dataSource={list}
                rowKey="id"
                loading={loading}
                pagination={{
                    pageSize,
                    total: count,
                    current: currentPage,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                scroll={{x: 1200}}
                onChange={onChangeTable}
            >
                <Column title="序号" dataIndex="id" width={80} fixed="left" />
                <Column title="账号名称" dataIndex="accountName" width={180} fixed="left" />
                <Column title="公司主体" dataIndex="corpName" />
                <Column title="账号" dataIndex="accountNo" />
                <Column title="开户行" dataIndex="bank" />
                <Column title="备注" dataIndex="remark" />
                <Column title="操作" key="set" align="center" width={80} fixed="right" render={(text, record) => (
                    <>
                        <div>
                            <a onClick={() => onEdit(record.id)}>修改</a>
                        </div>
                        <div>
                            {/* <Auth auths={text.operateAuth} code="delete"> */}
                            <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                                <a>删除</a>
                            </Popconfirm>
                            {/* </Auth> */}
                        </div>
                    </>
                )} />
            </Table>
            <Modal
                title={editId ? '公司账户信息修改' : '新增账户'}
                visible={fieldModal}
                onOk={formSubmit}
                onCancel={() => { setFieldModal(false) }}
                destroyOnClose={true}
                confirmLoading={saveLoading}
            >
                <Spin spinning={formLoading}>
                    <Form {...formItemLayout}>
                        {
                            editId ?
                                <FormItem label="账户名称">
                                    <div>{dataVal && dataVal.accountName}</div>
                                </FormItem> :
                                <FormItem label="账户名称">
                                    {getFieldDecorator('accountName', {
                                        rules: [{ required: true, message: '！请填写完整后确认' },
                                            // {pattern: /^[\u4e00-\u9fa5]{0,}$/, message: '仅能输入中文'}
                                        ]
                                    })(
                                        <Input placeholder="请输入账户名称" maxLength={30} />
                                    )}
                                </FormItem>
                        }
                        <FormItem label="公司主体">
                            {getFieldDecorator('corpId', {
                                rules: [{ required: true, message: '！请填写完整后确认' }]
                            })(<Select
                                placeholder="请选择"
                                dropdownMatchSelectWidth={false}
                                showSearch
                                optionFilterProp="children"
                                onSelect={(key, Option) => setCorpName(Option.props.children)}
                            >
                                {
                                    companyType.map((v, i) => {
                                        return <Option value={v.id} key={i}>{v.name}</Option>
                                    })
                                }
                            </Select>)}
                        </FormItem>
                        <FormItem label="账号">
                            {getFieldDecorator('accountNo', {
                                rules: [{ required: true, message: '！请填写完整后确认' }]
                            })(
                                <Input placeholder="请输入账号" />
                            )}
                        </FormItem>
                        <FormItem label="开户行">
                            {getFieldDecorator('bank', {
                                rules: [{ required: true, message: '！请填写完整后确认' }]
                            })(
                                <Input placeholder="请输入开户行" />
                            )}
                        </FormItem>
                        <FormItem label="备注">
                            {getFieldDecorator('remark')(
                                <Input.TextArea placeholder="请输入备注" maxLength={200} />
                            )}
                        </FormItem>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default Form.create()(Account)