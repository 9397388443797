/**
 * 模块名称:  公司内部往来款列表=>详情页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Descriptions,
	Spin,
	Icon,
	message,
	Steps,
	Button,
	Modal,
	Input,
	Form,
	Upload
} from 'antd'
import api from '@/api'
import { useSelector } from 'react-redux'
import { upLoadModule } from '@/utils/common'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'
import '../assets/style.scss'

const { Step } = Steps
const dItem = Descriptions.item
const { Dragger } = Upload

let areaTxt = '', result = ''

var temp

const Details = (props) => {
	const { history, location } = props
	const { getFieldDecorator } = props.form
	const draggerRef = useRef(null)
	const [detail, setDetail] = useState({})
	const [flow, setFlow] = useState([])
	const [modalVisible, setModalVisible] = useState(false)
	const [pageId, setPageId] = useState('')
	const [auditRecord, setAuditRecord] = useState([])
	const [loading, setLoading] = useState(false)
	const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
	const [files, setFiles] = useState([])
	const [receipt, setReceipt] = useState([])
	//修改
	const [reviseVisible, setReviseVisible] = useState(false)
	const [fileList, setFileList] = useState([])

	//操作权限
	const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
	const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
	const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
	const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
	const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填

	useEffect(() => {
		let plaintext = parseSearch(location.search).sign
		let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

		if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
			areaTxt = ''
			result = ''
			if (location.search) {
				setPageId(parseSearch(location.search).id)
				getInnerFundTransferDetail(parseSearch(location.search).id)
			} else {
				history.push({
					pathname: '/corpAccount/InternalAccount'
				})
			}
		} else {
			message.error('没有本页访问权限')
			setTimeout(() => {
				history.go(-2)
			}, 1000)
		}
	}, [])

	//获取详情
	const getInnerFundTransferDetail = (id) => {
		api.getInnerFundTransferDetail({ id: id }).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				console.log(data)
				if (data.status === 0) data.current = 0
				if (data.status === 1) data.current = 1
				if (data.status === 2) data.current = 2
				if (data.status === 3) data.current = 3
				data.basic.attachment && setFiles(data.basic.attachment)
				// console.log(JSON.parse(data.attachments))
				data.basic.proof && setReceipt(data.basic.proof)
				setDetail(data.basic)
				let auditFlow = data.auditStatus
				auditFlow.forEach((item, index, self) => {
					if (item.persons && item.persons.length) {
						self[index].persons = item.persons.join('，')
					}
				})
				setFlow(auditFlow)
				data.auditHistory && setAuditRecord(data.auditHistory)
				if (data.auditButton && JSON.stringify(data.auditButton) !== '[]') {
					setAuditAuth(true)
					setHasChargerPower(data.auditButton.hasChargerPower)
					setReplenish(data.auditButton.replenish)
					setReturnUp(data.auditButton.return_up)
					setRejectMsgRequire(data.auditButton.reject_msg_require)
				} else {
					setAuditAuth(false)
					setHasChargerPower(false)
					setReplenish(false)
					setReturnUp(false)
					setRejectMsgRequire(false)
				}
				// setOldData(data.oldData)
			} else {
				setDetail({})
				setFlow([])
				setAuditRecord([])
			}
			setLoading(false)
		})
	}

	//点击4个审批的按钮
	const leaveOutAudit = (no) => {
		areaTxt = ''
		result = no
		if (no == 0) {
			if (detail.luruhetongFlag) {
				setResPlaceholder('请输入审批意见')
				setModalVisible(true)
			} else {
				setResPlaceholder('请输入审批意见')
				setModalVisible(true)
			}

		} else if (no == 1) {
			//审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
			setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		} 
		// else {
		//   api.setInnerFundTransferAudit({
		//     id: pageId,
		//     type: result,
		//     remark: areaTxt
		//   }).then(res => {
		//     //todo 转上级或者补充资料 返回列表页
		//     let timer = setTimeout(() => {
		//       history.push({
		//         pathname: '/corpAccount/InternalAccount'
		//       })
		//       clearTimeout(timer)
		//     }, 1000)
		//   })
		// }
	}

	const oprateHandleOk = () => {
		if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {
			message.warning('请输入至少1个字符')
			return
		}
		let params = {
			id: pageId,
			type: result,
			remark: areaTxt
		}
		api.setInnerFundTransferAudit(params).then(res => {
			message.success('审批成功')
			setModalVisible(false)
			getInnerFundTransferDetail(pageId)
		})
	}

	//取消弹窗
	const oprateHandleCancel = () => {
		setModalVisible(false)
	}

	//同意的样式
	const agreeNode = () => {
		return result === 0 ? <>
			通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</> : result === 1 ? <>驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" /></> :
			<>转上级</>
	}

	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
	}

	//确认修改按钮
	const handleOk = () => {
		let uploadList = []
		for (let i = 0, k = fileList.length; i < k; i++) {
			if (fileList[i].status != 'removed') {
				uploadList.push({
					url: fileList[i].url,
					name: fileList[i].name
				})
			}
		}
		api.setInnerFundTransferEditProof({
			id: pageId,
			proof: uploadList
		}).then(res => {
			message.success('修改成功')
			setReviseVisible(false)
			setFileList([])
			getInnerFundTransferDetail(pageId)
		}).catch(err => console.log(err))
	}

	// 文件上传
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: ".zip, .pdf, .jpg, .png, .xls, .xlsx",
		limitNum: 20
	})

	//转大写
	const DX = (tranvalue) => {
		try {
			var i = 1;
			var dw2 = new Array("", "万", "亿");//大单位
			var dw1 = new Array("拾", "佰", "仟");//小单位
			var dw = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖");//整数部分用
			//以下是小写转换成大写显示在合计大写的文本框中     
			//分离整数与小数
			var source = splits(tranvalue);
			var num = source[0];
			var dig = source[1];

			//转换整数部分
			var k1 = 0;//计小单位
			var k2 = 0;//计大单位
			var sum = 0;
			var str = "";
			var len = source[0].length;//整数的长度
			for (i = 1; i <= len; i++) {
				var n = source[0].charAt(len - i);//取得某个位数上的数字
				var bn = 0;
				if (len - i - 1 >= 0) {
					bn = source[0].charAt(len - i - 1);//取得某个位数前一位上的数字
				}
				sum = sum + Number(n);
				if (sum != 0) {
					str = dw[Number(n)].concat(str);//取得该数字对应的大写数字，并插入到str字符串的前面
					if (n == '0') sum = 0;
				}
				if (len - i - 1 >= 0) {//在数字范围内
					if (k1 != 3) {//加小单位
						if (bn != 0) {
							str = dw1[k1].concat(str);
						}
						k1++;
					} else {//不加小单位，加大单位
						k1 = 0;
						temp = str.charAt(0);
						if (temp == "万" || temp == "亿")//若大单位前没有数字则舍去大单位
							str = str.substr(1, str.length - 1);
						str = dw2[k2].concat(str);
						sum = 0;
					}
				}
				if (k1 == 3)//小单位到千则大单位进一
				{ k2++; }
			}

			//转换小数部分
			var strdig = "";
			if (dig != "") {
				var n = dig.charAt(0);
				if (n != 0) {
					strdig += dw[Number(n)] + "角";//加数字
				}
				var n = dig.charAt(1);
				if (n != 0) {
					strdig += dw[Number(n)] + "分";//加数字
				}
			}
			if (strdig !== '') {
				if (str !== '') {
					str += "元" + strdig;
				} else {
					str += strdig;
				}

			} else {
				str += "元" + strdig + '整'
			}

		} catch (e) {
			return "0元";
		}
		return str;
	}

	//拆分整数与小数
	function splits(tranvalue) {
		var value = new Array('', '');
		temp = tranvalue.split(".");
		for (var i = 0; i < temp.length; i++) {
			value[i] = temp[i];
		}
		return value;
	}

	//退回列表页
	const onGoBack = () => {
		history.push({ pathname: '/corpAccount/InternalAccount', state: { goBack: 1 } })
	}

	return (
		<>
			<Spin spinning={loading}>
				{
					parseSearch(location.search).new && parseSearch(location.search).new == 1 && <Button onClick={onGoBack} type="primary" style={{
						position: 'absolute',
						right: 0,
						top: '-20px'
					}}>返回上一页</Button>
				}
				<div style={{ padding: '5px 0' }}>
					<div>
						<div style={{ fontSize: '14px', marginBottom: '5px', color: '#000' }}>
							业务编号：<span style={{ fontSize: '16px' }}>{detail.id}</span>
						</div>
					</div>
				</div>
				<div className="line"></div>
				<div className="cost-management-type">
					<div className="cost-management-type-title">当前状态 <Button type="primary" style={{ marginLeft: '20px' }}>{detail.statusName}</Button></div>
					<div>{detail.status == 5 ? `终止原因：${detail.reason}` : ''}</div>
					<div>
						{detail.status == 4 ? <>
							<span>付款凭证：</span>
							{receipt.length > 0 && receipt.map((v, i) => <a style={{ marginRight: '15px' }} key={i} href={v.url} target="_blank" rel="noopener noreferrer">{v.name} </a>)}
							{detail.hasPayEdit && <a style={{ marginLeft: '15px' }} onClick={() => setReviseVisible(true)}>修改</a>}
							<Modal
								title="修改"
								visible={reviseVisible}
								onOk={handleOk}
								onCancel={() => {
									setReviseVisible(false)
									setFileList([])
								}}
								destroyOnClose={true}
							>
								<div className="dragger-box" ref={draggerRef}>
									<Dragger
										{...uploadFiles}
									>
										<p className="ant-upload-drag-icon">
											<Icon type="inbox" />
										</p>
										<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
										<p className="ant-upload-hint">
											支持扩展名：xls、xlsx、png、jpg、pdf、zip 单个文件大小不超过5M，不超过20个附件。
										</p>
									</Dragger>
								</div>
							</Modal>
						</> : ''}
					</div>
					<div>{detail.status == 4 || detail.status == 5 ? `操作人：财务部 ${detail.financeOpeName}` : ''}</div>
				</div>
				<div className="line"></div>
				<div className="step-wrap">
					<div className="contract-list-title">审批进度</div>
					<Steps current={detail.current}>
						{
							flow.map((item, index, self) => {
								let status = 'wait'
								if (item.time && item.time.length > 1) {
									status = 'process'
								}
								return <Step status={status} title={item.nodeName} key={index} description={
									<>
										{item.persons && <div title={item.persons}>{item.persons}</div>}
										<div title={item.statusName}>{item.statusName}</div>
										<div title={item.time}>{item.time}</div>
									</>
								} />
							})
						}
					</Steps>
				</div>
				<div className="line"></div>
				<Descriptions title="基本信息" layout="horizontal" column={4} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
					<dItem label="转款类型">{detail.typeName}</dItem>
					<dItem></dItem>
					<dItem></dItem>
					<dItem></dItem>
					<dItem label="转出账户">{detail.fromAccountName}</dItem>
					<dItem label="转出账户主体">{detail.fromCompanyName}</dItem>
					<dItem label="账号">{detail.fromBankNumber}</dItem>
					<dItem label="开户行">{detail.fromBankOpen}</dItem>
					<dItem label="转入账户">{detail.toAccountName}</dItem>
					<dItem label="转入账户主体">{detail.toCompanyName}</dItem>
					<dItem label="账号">{detail.toBankNumber}</dItem>
					<dItem label="开户行">{detail.toBankOpen}</dItem>
					<dItem label="付款金额">{detail.money}</dItem>
					<dItem></dItem>
					<dItem></dItem>
					<dItem></dItem>
					<dItem label={<span style={{ color: 'red' }}>大写金额</span>}><span style={{ color: 'red' }}>{DX(detail.money)}</span></dItem>
				</Descriptions>
				<div className="line"></div>
				<Descriptions title="其他信息" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
					<dItem label="附件">
						<>
							{
								files.length > 0 ? files.map((item, i) => {
									return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
								}) : <div>无</div>
							}
						</>
					</dItem>
					<dItem label="备注">{detail.remark ? detail.remark : '无'}</dItem>
				</Descriptions>
				<div className="line"></div>
				<div className="record-list" style={{ marginTop: '10px', marginBottom: '50px' }}>
					<Descriptions title="审批记录" layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
						<dItem label="姓名"></dItem>
						<dItem label="时间"></dItem>
						<dItem label="状态"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>
					{
						auditRecord.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
								<dItem label="">{item.userInfo.staffName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.status}</dItem>
								<dItem label="">{item.chargeMsg}</dItem>
							</Descriptions>
						})
					}
				</div>
				{/* <div className="line"></div> */}
			</Spin>
			<div className="contract-details-btn">
				<div className="contract-details-btns">
					{auditAuth && <div className="btns">
						{/* {replenish && <Button onClick={debounce(() => { leaveOutAudit(3) }, 3000)} className="orange-btn">补充资料</Button>}
            {returnUp && <Button onClick={debounce(() => { leaveOutAudit(2) }, 3000)} className="orange-btn">转上级</Button>} */}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(1)} style={{ marginLeft: '15px' }}>驳回</Button>}
						{hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary" style={{ marginLeft: '15px' }}>通过</Button>}
					</div>}
					<Modal
						title={agreeNode()}
						visible={modalVisible}
						onOk={debounce(() => { oprateHandleOk() }, 3000)}
						onCancel={oprateHandleCancel}
						destroyOnClose={true}
						footer={[
							<Button key="back" onClick={oprateHandleCancel}>取消</Button>,
							<Button key="submit" type="primary" onClick={debounce(() => { oprateHandleOk() }, 3000)}>
								确定
							</Button>]}
					>
						<div className="officework-modal-content">
							<span className="label">审批意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
						</div>
					</Modal>
				</div>
			</div>
		</>
	)
}

export default Form.create()(Details)