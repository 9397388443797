/**
 * 模块名称: 公司资产 => 硬件设备管理
 * @author liujingxue@372163.com
 */


import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Hardware from './Hardware'
import Details from './Details'

const HardwareIndex = ({ match, history }) => {

	return (
		<Switch>
			<Route path={match.path + '/details'} component={Details} />
			<Route component={Hardware} />
		</Switch>
	)
}

export default HardwareIndex