/*
 * @Author: zhuyan 
 * @Date: 2021-11-25 15:39:38 
 * 公司资产 => 软件账号添加
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Input,
  Select,
  message,
  DatePicker,
  Radio
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import moment from 'moment'

const FormItem = Form.Item
const Option = Select.Option

const Addsystem = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form
  const { seteditVisible, getList, setformLoading, addType, editId } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [selectData, setSelectData] = useState({
    company: [], // 主体
    status: [],
    isRequest: false
  })
  const [dataVal, setDataVal] = useState('') // 使用人信息
  const [userId, setUserId] = useState('')
  const [detail, setDetail] = useState(null)

  useEffect(() => {
    onEdit()
  }, [])

  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      api.getHeadCompanyList({ limit: global.paramsLimit }),
    ]).then(list => {
      setformLoading(false)
      setSelectData({
        company: list[0],
        status: [{
          id: 1,
          name: '使用'
        }, {
          id: 2,
          name: '注销'
        }, {
          id: 3,
          name: '库存'
        }],
        isRequest: true
      })
      return list
    }).catch(() => setformLoading(false))
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  // 编辑框
  const onEdit = () => {
    if (addType == 0) { // 新增
      getSelectData()
    }
    if (addType == 1) { // 编辑
      api.getAssetSoftAccountDetail({ id: editId }).then(res => {
        console.log(res)
        const { accountPlatform, accountName, nickname, companyId, openingDate, endDate, useNote,
        } = res
        getSelectData().then(() => {
          setDetail(res)
          setFieldsValue({
            accountPlatform,
            accountName,
            nickname,
            companyId,
            openingDate: openingDate ? moment(openingDate) : null,
            endDate: endDate ? moment(endDate) : null,
            useNote
          })
        })
      })
    }
    if (addType == 2) { // 调拨
      api.getAssetSoftAccountDetail({ id: editId }).then(res => {
        const { status, userNumber, address, bindPhone, bindPhonePersonName, bindPhonePersonIdno, isNameWithCompany, bindWechat, bindWechatPersonName, remark
        } = res
        getSelectData().then(() => {
          setDetail(res)
          onChangeNoInit(userNumber)
          setFieldsValue({
            status,
            userNumber,
            address,
            bindPhone,
            bindPhonePersonName,
            bindPhonePersonIdno,
            isNameWithCompany,
            bindWechat,
            bindWechatPersonName,
            remark
          })
        })
      })
    }
  }

  // 保存
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        if (vals.status === 1 && +addType !== 1 && userId === '') {
          return message.error("使用人员工编号填写有误，请重新填写")
        }
        setSaveLoading(true)
        vals.openingDate = vals.openingDate ? moment(vals.openingDate).format('YYYY-MM-DD') : undefined
        vals.endDate = vals.endDate ? moment(vals.endDate).format('YYYY-MM-DD') : undefined
        let params = { ...vals }
        if (+addType === 2) {
          api.getAssetSoftAccountchangeUser(params)
            .then(() => {
              setSaveLoading(false)
              seteditVisible(false)
              message.success('保存成功')
              getList()
            })
            .catch(() => setSaveLoading(false))
        } else {
          api.getAssetSoftAccountSave(params)
            .then(() => {
              setSaveLoading(false)
              seteditVisible(false)
              message.success('保存成功')
              getList()
            })
            .catch(() => setSaveLoading(false))
        }
      }
    })
  }

  const onChangeNo = (e) => {
    setDataVal('')
    setUserId('')
    if (e.target.value) {
      api.getStaffInfobyStaffNo({ staffNo: e.target.value }).then(res => {
        if (res.length !== 0) {
          setDataVal(res)
          setUserId(res.id)
        } else {
          setDataVal('')
          setUserId('')
        }
      })
    }
  }

  const onChangeNoInit = (staffNo) => {
    setDataVal('')
    setUserId('')
    if (staffNo) {
      api.getStaffInfobyStaffNo({ staffNo }).then(res => {
        if (res.length !== 0) {
          setDataVal(res)
          setUserId(res.id)
        } else {
          setDataVal('')
          setUserId('')
        }
      })
    }
  }

  return (
    <div>
      {
        // 编辑
        +addType === 1 ?
          <Form onSubmit={formSubmit} {...formItemLayout}>
            <FormItem label="资产编号">
              <span>{detail && detail.number}</span>
            </FormItem>
            <FormItem label="账号平台">
              {getFieldDecorator('accountPlatform', {
                rules: [{ required: true, message: '请输入账号平台' }]
              })(
                <Input placeholder="请输入账号平台" />
              )}
            </FormItem>
            <FormItem label="账号">
              {getFieldDecorator('accountName', {
                rules: [{ required: true, message: '请输入账号' }]
              })(
                <Input placeholder="请输入账号" />
              )}
            </FormItem>
            <FormItem label="昵称">
              {getFieldDecorator('nickname', {
                rules: [{ required: false, message: '请输入昵称' }]
              })(
                <Input placeholder="请输入昵称" />
              )}
            </FormItem>
            <FormItem label="公司主体">
              {getFieldDecorator('companyId', {
                rules: [{ required: true, message: '请选择公司主体' }]
              })(
                <Select placeholder="请选择"
                  showSearch={true}
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                >
                  {selectData.company.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="账号开通时间">
              {getFieldDecorator('openingDate', {
                rules: [{ required: false, message: '请输入账号开通时间' }]
              })(
                <DatePicker placeholder="请选择" allowClear={false} style={{ width: '100%' }} />
              )}
            </FormItem>
            <FormItem label="账号到期时间">
              {getFieldDecorator('endDate', {
                rules: [{ required: false, message: '请输入账号到期时间' }]
              })(
                <DatePicker placeholder="请选择" allowClear={false} style={{ width: '100%' }} />
              )}
            </FormItem>
            <FormItem label="用途">
              {getFieldDecorator('useNote')(
                <Input placeholder="请输入用途" />
              )}
            </FormItem>
            <BtnGroup onCancel={() => seteditVisible(false)} loading={saveLoading} />
          </Form> : null
      }
      {
        +addType !== 1 ?
          // 新增0或调拨2
          <Form onSubmit={formSubmit} {...formItemLayout}>
            {
              +addType === 0 ?
                <>
                  <FormItem label="账号平台">
                    {getFieldDecorator('accountPlatform', {
                      rules: [{ required: true, message: '请输入账号平台' }]
                    })(
                      <Input placeholder="请输入账号平台" />
                    )}
                  </FormItem>
                  <FormItem label="账号">
                    {getFieldDecorator('accountName', {
                      rules: [{ required: true, message: '请输入账号' }]
                    })(
                      <Input placeholder="请输入账号" />
                    )}
                  </FormItem>
                  <FormItem label="昵称">
                    {getFieldDecorator('nickname', {
                      rules: [{ required: false, message: '请输入昵称' }]
                    })(
                      <Input placeholder="请输入昵称" />
                    )}
                  </FormItem>
                  <FormItem label="公司主体">
                    {getFieldDecorator('companyId', {
                      rules: [{ required: true, message: '请选择公司主体' }]
                    })(
                      <Select placeholder="请选择"
                        showSearch={true}
                        optionFilterProp="children"
                        dropdownMatchSelectWidth={false}
                      >
                        {selectData.company.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                </> :
                <>
                  <FormItem label="资产编号">
                    <span>{detail && detail.number}</span>
                  </FormItem>
                  <FormItem label="账号平台">
                    <span>{detail && detail.accountPlatform}</span>
                  </FormItem>
                  <FormItem label="账号">
                    <span>{detail && detail.accountName}</span>
                  </FormItem>
                  <FormItem label="昵称">
                    <span>{detail && detail.nickname}</span>
                  </FormItem>
                </>
            }
            <FormItem label="状态">
              {getFieldDecorator('status', {
                rules: [{ required: true, message: '请选择状态' }]
              })(
                <Select placeholder="请选择"
                  showSearch={true}
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  onSelect={(key, Option) => {
                    setDataVal('')
                  }}
                >
                  {selectData.status.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            {
              getFieldValue('status') === undefined || getFieldValue('status') === 1 ?
                <>
                  <FormItem label="负责人员工编号">
                    {getFieldDecorator('userNumber', {
                      rules: [{ required: true, message: '请输入负责人员工编号' }]
                    })(
                      <Input placeholder="负责人员工编号" onChange={onChangeNo} />
                    )}
                  </FormItem>
                  {
                    dataVal !== '' &&
                    <>
                      <FormItem label="负责人姓名">
                        <span>{dataVal.staffName}</span>
                      </FormItem>
                      <FormItem label="直属上级">
                        <span>{dataVal.leaderName}</span>
                      </FormItem>
                      <FormItem label="所属部门">
                        <span>{dataVal.departmentName}</span>
                      </FormItem>
                      <FormItem label="所在事业群">
                        <span>{dataVal.groupName}</span>
                      </FormItem>
                    </>
                  }
                  <FormItem label="所在位置">
                    {getFieldDecorator('address', {
                      rules: [{ required: false, message: '请输入所在位置' }]
                    })(
                      <Input placeholder="请输入所在位置" />
                    )}
                  </FormItem>
                </> : null
            }
            <FormItem label="绑定手机号">
              {getFieldDecorator('bindPhone', {
                rules: [{ required: false, message: '请输入绑定手机号' }]
              })(
                <Input placeholder="请输入绑定手机号" />
              )}
            </FormItem>
            <FormItem label="手机号实名认证人">
              {getFieldDecorator('bindPhonePersonName', {
                rules: [{ required: false, message: '请输入手机号实名认证人' }]
              })(
                <Input placeholder="请输入手机号实名认证人" />
              )}
            </FormItem>
            <FormItem label="身份证">
              {getFieldDecorator('bindPhonePersonIdno', {
                rules: [{ required: false, message: '请输入身份证' }]
              })(
                <Input placeholder="请输入身份证" />
              )}
            </FormItem>
            <FormItem label="手机号是否为公司名义开通" labelCol={{span: 8}}>
              {getFieldDecorator('isNameWithCompany')(
                <Radio.Group>
                  <Radio value={1}>是</Radio>
                  <Radio value={2}>否</Radio>
                </Radio.Group>
              )}
            </FormItem>
            <FormItem label="绑定微信号">
              {getFieldDecorator('bindWechat', {
                rules: [{ required: false, message: '请输入绑定微信号' }]
              })(
                <Input placeholder="请输入绑定微信号" />
              )}
            </FormItem>
            <FormItem label="微信号实名认证人">
              {getFieldDecorator('bindWechatPersonName', {
                rules: [{ required: false, message: '请输入微信号实名认证人' }]
              })(
                <Input placeholder="请输入微信号实名认证人" />
              )}
            </FormItem>
            {
              +addType === 0 ?
                <>
                  <FormItem label="账号开通时间">
                    {getFieldDecorator('openingDate', {
                      rules: [{ required: true, message: '请输入账号开通时间' }]
                    })(
                      <DatePicker placeholder="请选择" allowClear={false} style={{ width: '100%' }} />
                    )}
                  </FormItem>
                  <FormItem label="账号到期时间">
                    {getFieldDecorator('endDate', {
                      rules: [{ required: true, message: '请输入账号到期时间' }]
                    })(
                      <DatePicker placeholder="请选择" allowClear={false} style={{ width: '100%' }} />
                    )}
                  </FormItem>
                  <FormItem label="账号用途">
                    {getFieldDecorator('useNote')(
                      <Input placeholder="请输入账号用途" />
                    )}
                  </FormItem>
                </> : null
            }
            <FormItem label="备注">
              {getFieldDecorator('remark')(
                <Input placeholder="请输入备注" />
              )}
            </FormItem>
            <BtnGroup onCancel={() => seteditVisible(false)} loading={saveLoading} />
          </Form> : null
      }
    </div>
  );
}

export default Form.create()(Addsystem)
