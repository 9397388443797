/*
 * @Author: zhuyan 
 * @Date: 2021-11-24 17:07:42 
 * 公司资产 => 软件账号管理
 */

import React, { useState, useEffect, useRef } from 'react';
import api from '@/api';
import { Link } from 'react-router-dom';
import { Table, Button, Alert, Spin, Drawer, Popconfirm, message } from 'antd';
import CorpQuanQian from '@/components/CorpQuanQian';
import FiltersForm from '@/components/FiltersForm';
import { parseSearch } from '@/utils';
import Auth from '@/components/AuthMiddleware';
import AddSoftware from './module/AddSoftware'
import Import from '@/components/Import'
import CryptoJS from 'crypto-js'
let showVal = {}

const System = (props) => {
  const { history, match, location } = props;
  const search = parseSearch(location.search);

  const initParams = {
    companyId: '',
    page: search.page || 1,
    limit: search.limit || 10,
  };

  const getParams = () => {
    if (!history.location.state) {
      return initParams
    } else {
      return {...initParams, ...showVal}
    }
  }

  const [states, setStates] = useState({
    dataSource: [], // 列表
    loading: true,
    total: 0, // 总条数
    filter: {
      groupList: [], // 事业群
      department: [], // 部门
      status: [{
        id: 1,
        name: '使用'
      }, {
        id: 2,
        name: '注销'
      }, {
        id: 3,
        name: '库存'
      }], // 筛选：状态
    },
    // 筛选条件：列表接口参数
    params: getParams(),
  });
  const { dataSource, filter, loading, total, params } = states;
  const ref = useRef()
  const ImportRef = useRef()

  const [authList, setAuthList] = useState([]); // 按钮权限
  const [groupVal, setGroupVal] = useState(undefined)
  const [editVisible, seteditVisible] = useState(false)
  const [addType, setAddType] = useState(0)
  const [editId, setEditId] = useState(null)
  const [formLoading, setformLoading] = useState(false)
  const [showImport, setShowImport] = useState(false)

  useEffect(() => {
    getList();
    const { page, limit, ...others } = params
    showVal = {...params}
  }, [params]);


  useEffect(() => {
    getPageAuth();
  }, []);

  // 获取列表
  const getList = () => {
    api
      .getAssetSoftAccountList(params)
      .then((res) => {
        setStates((current) => ({
          ...current,
          loading: false,
          dataSource: res.list,
          total: res.count,
        }));
      })
      .catch(() => {
        setStates((current) => ({
          ...current,
          loading: false,
        }));
      });
  };

  // 获取按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then((list) => {
      setAuthList(list);
    });
  };

  // 事业群
  const getSelectGroupList = () => {
    api.getSelectGroupList({ limit: global.paramsLimit }).then(data => {
      setStates((current) => ({
        ...current,
        filter: {
          ...filter,
          groupList: data.list,
        },
      }));
    })
  }

  // 部门
  const getSelectDepartmentList = (val) => {
    api.getSelectDepartmentList({ limit: global.paramsLimit, groupId: val }).then(data => {
      setStates((current) => ({
        ...current,
        filter: {
          ...filter,
          department: data.list,
        },
      }));
    })
  }

  // 点击选择所属公司
  const onChangeCorp = (data) => {
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...params,
        companyId: data.map((item) => item.id),
      },
    }));
    history.replace(match.path);
  };

  // 分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: limit } = pagination;
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...params,
        page: page,
        limit: limit,
      },
    }));
    history.replace(match.path + '?page=' + page + '&limit=' + limit);
  };

  // 点击筛选
  const onSubmit = (values) => {
    initParams.page = 1;
    initParams.limit = 10;
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...initParams,
        companyId: params.companyId,
        ...values,
      },
    }));
    history.replace(match.path);
  }

  // 编辑
  const onEdit = (data) => {
    seteditVisible(true)
    setformLoading(true)
    setAddType(1)
    setEditId(data.id)
  }

  // 调拨
  const changeUser = (data) => {
    seteditVisible(true)
    setformLoading(true)
    setAddType(2)
    setEditId(data.id)
  }

  //申请
  const onAddSystem = () => {
    setEditId(null)
    setAddType(0)
    seteditVisible(true)
    setformLoading(true)
  }

  // 删除
  const onDelete = (id) => {
    api.getAssetSoftAccountDelete({ id }).then(() => {
      message.success('删除成功')
      getList()
    })
  }

  // 导入确定
  const onConfirmImport = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== 'removed') {
        api.getAssetSoftAccountImport({
          path: fileList[0].url
        }).then(res => {
          getList()
          message.success('导入成功')
          ImportRef.current.onLoading()
          setShowImport(false)
        }).catch(res => {
          // console.log('导入', res)
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  const onShowImportDialog = () => {
    ImportRef.current.onRemoved()
    setShowImport(true)
  }

  const columns = [
    {
      title: '资产编号',
      dataIndex: 'number',
      valueType: 'Input',
      fixed: 'left',
      width: 120,
      fieldProps: {
        placeholder: '请输入资产编号',
      },
    },
    {
      title: '账号平台',
      dataIndex: 'accountPlatform',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入账号平台',
      },
    },
    {
      title: '账号',
      dataIndex: 'accountName',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入账号',
      },
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入昵称',
      },
    },
    {
      title: '主体',
      dataIndex: 'companyName'
    },
    {
      title: '账号负责人',
      dataIndex: 'userName',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入账号负责人',
      },
    },
    {
      title: '负责人员工编号',
      dataIndex: 'userNumber',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入负责人员工编号',
      },
    },
    {
      title: '所属事业群',
      dataIndex: 'userGroupId',
      valueType: 'Select',
      optionFilterProp: "children",
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.groupList.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        dropdownMatchSelectWidth: false,
        onDropdownVisibleChange: (open) => {
          if (open) {
            getSelectGroupList();
          }
        },
        onChange: (val) => {
          ref.current.setFieldsValue({
            'userGroupId': val,
            'userDepartmentId': undefined,
          })
          setGroupVal(val)
        }
      },
      render: (text, record) => record.userGroupName,
    },
    {
      title: '所在部门',
      dataIndex: 'userDepartmentId',
      valueType: 'Select',
      optionFilterProp: "children",
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.department.map((item) => ({
          label: item.name+ '（' + item.companyName + '）',
          value: item.id,
        })),
        dropdownMatchSelectWidth: false,
        onDropdownVisibleChange: (open) => {
          if (open) {
            getSelectDepartmentList(groupVal);
          }
        },
      },
      render: (text, record) => record.userDepartmentName,
    },
    {
      title: '状态',
      dataIndex: 'status',
      valueType: 'Select',
      optionFilterProp: "children",
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.status.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      },
      render: (text, record) => record.statusCn,
    },
    {
      title: '账号开通时间',
      dataIndex: 'openingDate',
      valueType: 'RangePicker',
      search: {
        transform: (value) => {
          return {
            openingDateMin: value[0].format('YYYY-MM-DD'),
            openingDateMax: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '账号到期时间',
      dataIndex: 'endDate',
      valueType: 'RangePicker',
      search: {
        transform: (value) => {
          return {
            endDateMin: value[0].format('YYYY-MM-DD'),
            endDateMax: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '账号注销时间',
      dataIndex: 'cancelDate',
      valueType: 'RangePicker',
      search: {
        transform: (value) => {
          return {
            cancelDateMin: value[0].format('YYYY-MM-DD'),
            cancelDateMax: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '备注',
      dataIndex: 'remark'
    },
    {
      title: '操作',
      key: 'oprate',
      fixed: 'right',
      width: 80,
      align: "center",
      render: (text, record) => {
        return (
          <>
            <Auth auths={text.operateAuth} code="detail">
                <Link to={`${match.path}/detail?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>
                  详情
                </Link>
            </Auth>
            <Auth auths={text.operateAuth} code="edit">
              <Button
                type='link'
                size='small'
                onClick={() => onEdit(record)}
              >
                编辑
              </Button>
            </Auth>
            <Auth auths={text.operateAuth} code="changeUser">
              <Button
                type='link'
                size='small'
                onClick={() => changeUser(record)}
              >
                调拨
              </Button>
            </Auth>
            <Auth auths={text.operateAuth} code="delete">
              <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <Button
                  type='link'
                  size='small'
                >
                  删除
                </Button>
              </Popconfirm>
            </Auth>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CorpQuanQian onChange={onChangeCorp} />
      <FiltersForm ref={ref} columns={columns} initialValues={params} isNo={true} onSubmit={onSubmit} onReset={onSubmit} />
      <div className='line'></div>
      <div className='add-wrap'>
        <Auth auths={authList} code='add'>
          <Button
            type='primary'
            title='添加'
            onClick={onAddSystem}
            style={{ marginRight: 10 }}
          >
            添加
          </Button>
        </Auth>
        <Auth auths={authList} code='import'>
          <Button
            type='primary'
            title='导入'
            onClick={() => onShowImportDialog(true)}
            style={{ marginRight: 10 }}
          >
            导入
          </Button>
        </Auth>
      </div>
      {/* <Alert
        className='corp-count'
        message={`总计：${total}条数据`}
        type='info'
        showIcon
      /> */}
      <Table
        size='small'
        columns={columns}
        dataSource={dataSource}
        rowKey='id'
        loading={loading}
        className='askforleave-table'
        pagination={{
          pageSize: parseInt(params.limit),
          total: total,
          current: parseInt(params.page),
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        // scroll={{ x: 'max-content' }}
        scroll={{ x: 1600, y: 500 }}
      ></Table>
      <Drawer
        title={editId ? addType === 2 ?'资产调拨':'软件账号信息编辑' : '资产添加'}
        width={600}
        onClose={() => seteditVisible(false)}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <AddSoftware
            setformLoading={setformLoading}
            seteditVisible={seteditVisible}
            getList={getList}
            addType={addType}
            editId={editId}
          />
        </Spin>
      </Drawer>
      <Import
        onSearch={onConfirmImport}
        visibleFile={showImport}
        onCloseImport={() => setShowImport(false)}
        ImportTemplate="/api/File/DownTemplate?url=/templates/asset_soft_account.xlsx&name=导入软件账号模板"
        ImportRef={ImportRef}
      />
    </>
  );
};

export default System
